import $ from 'jquery';

class cdpStatisticsMostPopularCitiesOnline{
  constructor() {
    if ($('#cdp-stat-most-popular-cities-online').length > 0){
      var _this = this
      this.loading()
      this.init()
      setInterval(function() {
        _this.reload()
      }, 10000);
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-stat-most-popular-cities-online').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $('#cdp-stat-most-popular-cities-online #cities-table').empty();
    response.cities_with_data.forEach((e) => {
      var tr = $('<tr></tr>')
      var tdCity = $('<td class="tx-left"></td>').text(`${e.city}`)
      var tdCount = $('<td class="tx-right"></td>').text(`${this.transform(e.count)} (${this.transform(e.profiles_percentage)}%)`)

      tr.append(tdCity, tdCount)
      $('#cdp-stat-most-popular-cities-online #cities-table').append(tr)
    })
    
    this.loaded();
  }

  loading(){
    $('#cdp-stat-most-popular-cities-online').addClass('loading');
  }

  loaded(){
    $('#cdp-stat-most-popular-cities-online').removeClass('loading');
  }

  reload(){
    this.init()
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsMostPopularCitiesOnline;