import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, doubleAxes, value, percentage } from '../options';

class MainAnalyticsCodesByParticipant {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts_main_analytics_codes_by_participant')
    let check = $('canvas').is('#charts_main_analytics_codes_by_participant')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let urlParams = 'project_id=' + project_id +
                    '&start_week=' + first_week +
                    '&end_week=' + end_week +
                    '&days_from=' + days_from +
                    '&days_to=' + days_to +
                    '&env=' + env +
                    '&period_filter_type=' + period_filter_type

    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/main_analytics_codes_by_participant?' + urlParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels,

          datasets: [
            {
              data: response.data,
              backgroundColor: response.color,
              hoverBackgroundColor: response.color,
              borderColor: response.color,
              hoverBorderColor: response.color,
              fill: true,
              lineTension: 0
            }
          ]
        };

        let container = $('#charts_main_analytics_codes_by_participant_container')
        container.find('span.value').text(response.total_count)
        container.find('small.trend_value').text(response.trend.value + '%')

        if(response.trend.point == 'up')
        {
          container.find('i.tx-success').removeClass('hidden')
        }
        if(response.trend.point == 'down')
        {
          container.find('i.tx-danger').removeClass('hidden')
        }

        options['legend']['display'] = false

        new Chart(ctx, {
          type: response.chart_type,
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    MainAnalyticsCodesByParticipant.Chart();
  }
}

export default MainAnalyticsCodesByParticipant;
