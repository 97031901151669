import $ from 'jquery';
import PerfectScrollbar from '../ticketsChat/perfect-scrollbar.min'
import lightSlider from '../ticketsChat/lightslider.min'

class SupportChat {
  constructor() {
    if ($('#chat__section').length) {
      this.init();
      this.initScroll();
      this.initData();
    }
  }

  init() {
    //Работа перетаскивания изображения в чат
    $("#chat__section .chat__window").bind('dragenter', function(e) {
      $('#chat__section #add_file').addClass('drag');
    })
    $('#chat__section #add_file').bind('dragleave', function(e) {
      $('#chat__section #add_file').removeClass('drag');
    }).bind('dragend', function(e) {
      $('#chat__section #add_file').removeClass('drag');
    }).bind('dragexit', function(e) {
      $('#chat__section #add_file').removeClass('drag');
    }).bind('drop', function(e) {
      if (e.originalEvent.dataTransfer.files.length) {
        $('#chat__section #add_file').removeClass('drag');
      }
    });
    //Для отправки изображения
    var this_class = this
    $(document).on('change', '#chat__section #add_file', function(e){
      if ($(this)[0].files[0].type == 'image/jpeg' || $(this)[0].files[0].type == 'image/png') {
        //Здесь надо сразу отправлять изображение без прикрепления и очищать форму:
        var formData = new FormData();
        formData.append('ticket[body]', 'Изображение');
        formData.append('ticket[file_url]', $(this)[0].files[0]);
        var chat_id = $('#chat__section #azChatBody').data('chat_id')
        if (chat_id == undefined) {
          chat_id = ''
        }
        $.ajax({
          url: `/user_tickets?ticket_id=${chat_id}`,
          method: 'POST',
          dataType: 'script',
          data: formData,
          contentType: false,
          processData: false
        }).success(function(){
          this_class.initScroll()
        })
        $('#chat__section #file_send').trigger("reset");
      } else {
        $('#chat__section #file_send').trigger("reset");
      }
    });
    //Работа скрепки, прикрепляющей изображение
    $(document).on('click', '#chat__section .chat__add-icon', function(e){
      e.preventDefault();
      $('#chat__section #add_file').trigger('click');
    });
    //Работа вставки картинки из буфера обмена
    $(document).on('paste', '#chat__section #chat__text', function(e){
      if (e.originalEvent.clipboardData.getData('text/plain') == '') {
        $('#chat__section #add_file').focus();
        $('#chat__section #add_file').trigger('paste');
        if (e.originalEvent.clipboardData.files.length) {
          $('#chat__section #add_file')[0].files = e.originalEvent.clipboardData.files;
          $('#chat__section #add_file').trigger('change');
        }
      }
    });
    //Работа кнопки открыть/закрыть чат
    $(document).on('click', '#chat__section #chat__open', function(){
      $('#chat__section #chat__open').addClass('hidden');
      $('#chat__section .chat__window').removeClass('closed');
      $('#chat__section #azChatBody').scrollTop($('#chat__section #azChatBody').prop('scrollHeight'));
    });
    $(document).on('click', '#chat__section .close-chat', function(){
      $('#chat__section #chat__open').removeClass('hidden');
      $('#chat__section .chat__window').addClass('closed');
    });
    //Работа кнопки открыть/закрыть список чатов
    $(document).on('click', '#chat__section .open-list', function(){
      if($('#chat__section .chat__content-list').hasClass('d-none')){
        $('#chat__section .chat__content-list').removeClass('d-none');
        $(this).addClass('active');
      }else{
        $('#chat__section .chat__content-list').addClass('d-none');
        $(this).removeClass('active');
      }
    });
    //Работа кнопок открытия чата
    $(document).on('click', '#chat__section #azChatList .media', function(){
      $.ajax({
        url: '/user_tickets?opened_ticket_id=' + $(this).data('chat_id'),
        method: 'GET',
        dataType: 'script'
      }).success(function(){
        this_class.initScroll()
      })
    });
    //Работа кнопки отправки сообщения
    $(document).on('click', '#chat__section .az-msg-send', function(e){
      e.preventDefault()
      var chat_id = $('#chat__section #azChatBody').data('chat_id')
      if (chat_id == undefined) {
        chat_id = ''
      }
      $.ajax({
        url: '/user_tickets?ticket_id=' + chat_id,
        method: 'POST',
        data: {
          ticket: {
            body: $('#chat__section #chat__text').val()
          }
        },
        dataType: 'script'
      }).success(function(){
        this_class.initScroll()
      })
    });
  }

  initScroll() {
    //Работа скролла чата
    $('#chat__section #azChatBody').scrollTop($('#chat__section #azChatBody').prop('scrollHeight'));
    new PerfectScrollbar('#chat__section #azChatBody', {
      suppressScrollX: true
    });

    const azChatBody = new PerfectScrollbar('#chat__section #azChatBody', {
      suppressScrollX: true
    });

    $('#chat__section #azChatBody').scrollTop($('#chat__section #azChatBody').prop('scrollHeight'));

    //Работа скролла списка чатов
    if($('#chat__section #azChatList').length){
      $('#chat__section #azChatList').scrollTop($('#chat__section #azChatList').prop('scrollHeight'));
      new PerfectScrollbar('#chat__section #azChatList', {
        suppressScrollX: true
      });

      const azChatList = new PerfectScrollbar('#chat__section #azChatList', {
        suppressScrollX: true
      });

      $('#chat__section #azChatList').scrollTop($('#chat__section #azChatList').prop('scrollHeight'));
    }
  }

  initData() {
    $.ajax({
      url: '/user_tickets',
      method: 'GET',
      dataType: 'script'
    })
  }
}

export default SupportChat;