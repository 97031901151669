import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class GaUserGeo {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__ga_user_geo');
    let check = $('canvas').is('#charts__ga_user_geo');
    let project_id = $('[data-project-id]').data('project-id')
    if(check) {
      $.ajax({
        url: '/api/v1/statistics/ga_user_geo?project_id=' + project_id,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let colors_300 = []
        let colors_500 = []
        colors.reset()
        for(let label in response.labels){
          let color = colors.getColor()
          console.log(color)
          colors_300.push(color[300])
          colors_500.push(color[500])
          colors.next()
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: colors_300,
              hoverBackgroundColor: colors_500,
              borderColor: colors_500,
              hoverBorderColor: colors_500,
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };
        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: pie
        });
      }
    }
  }

  static onInit() {
    GaUserGeo.Chart();
  }
}

export default GaUserGeo;
