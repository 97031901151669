import $ from 'jquery';
import Tribute from "tributejs";

class emailTemplatesAutocomplete {
  constructor() {
    if ($('#new_email_template').length || $('.edit_email_template').length) {
      this.init($('#new_email_template').length ? $('#new_email_template') : $('.edit_email_template') )
    }
  }

  tribute;

  init(form) {
    let pathName = window.location.pathname.split('/')
    if (form.selector === '.edit_email_template'){
      delete pathName.splice(pathName.length - 2)
    }
    let editPathName = pathName.join('/')
    if (form.find('#email_template_kind').val()){
      $.getJSON((form.selector === '.edit_email_template' ? editPathName + '/' : '') + 'autocomplete_namespaces?kind=' + form.find('#email_template_kind').val(), (data) => {
        if(data.table.namespace){
          let collection = {
            noMatchTemplate: function () {
              return '<span style:"visibility: hidden;"></span>';
            },
            collection: [
              {
                trigger: '{{',
                values: [],
                selectClass: 'active',
                containerClass: 'list-group',
                itemClass: 'list-group-item p-1',
                selectTemplate: function (item) {
                  return item.original.value;
                }
              }
            ]
          }

          for (const [key, value] of Object.entries(data.table.namespace)) {
            collection.collection[0].values.push({
              key: `{{${key}}} - ${value}`,
              value: `{{${key}}}`
            })
          }
          if(!this.tribute){
            this.tribute = new Tribute(collection)
            this.tribute.attach(document.getElementById('email_template_body'))
            this.tribute.attach(document.getElementById('email_template_subject'))
          } else {
            this.tribute.append(0, collection.collection[0].values, true)
          }
        }
      })
    } else {
      this.tribute.append(0, [], true)
    }


    form.find('#email_template_kind').change(()=>{
      console.log('iinit')
      this.init(form)
    })

  }

}

export default emailTemplatesAutocomplete;