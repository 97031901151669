import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes } from '../options';

class RegisteredCodesMilVsAll {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registered-codes-mil-vs-all');
    let check = $('canvas').is('#charts__registered-codes-mil-vs-all');
    let project_id = $('[data-project-id]').data('project-id')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: `/api/v1/statistics/registered_codes_mil_vs_all?project_id=${project_id}` + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let colors_300 = []
        let colors_500 = []
        colors.reset()
        for(let label in response.labels){
          let color = colors.getColor()
          console.log(color)
          colors_300.push(color[300])
          colors_500.push(color[500])
          colors.next()
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: colors_300,
              hoverBackgroundColor: colors_500,
              borderColor: colors_500,
              hoverBorderColor: colors_500,
              fill: true,
              datalabels: {
                color: colors.white.primary,
                formatter: function(value, ctx) {
                  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                }
              }
            }
          ]
        };
        new Chart(ctx, {
          type: 'bar',
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: {
            legend: { display: false },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: function(value, index, values) {
                    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  }
                }
              }]
            }
          }
        });
      }
    }
  }

  static onInit() {
    RegisteredCodesMilVsAll.Chart();
  }
}

export default RegisteredCodesMilVsAll;
