import $ from 'jquery';
import { colors } from '../../charts/colors';
import { singleAxes } from '../../charts/options';

class ChartAgesCount {
  constructor() {
    let elementExists = $(this.elementId()).length > 0
    let isCanvas = $('canvas').is(this.elementId());

    if (elementExists && isCanvas){
      this.loading()
      this.init()
    }
  }

  init() {
    let thisObject = this;

    $(document).ready(function() {
      setTimeout(function() {
        $.ajax({
          url: $(thisObject.elementId()).data('url'),
          method: 'GET',
          dataType: 'json',
          data: { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) },
          success: function (response) {
            thisObject.initChart(response)
            thisObject.loaded(response)
          }
        });
      }, 1000);
    })
  }

  initChart(response){
    let ctx = $(this.elementId());

    let value_with_percentage = {
      color: colors.white.primary,
      backgroundColor: '#b4bdce',
      padding: 1,
      font: {
        size: 9
      },
      textAlign: 'center',
      formatter: function(value, ctx) {
        let amount = 0;
        let dataArray1 = ctx.chart.data.datasets[0].data;
        let dataArray2 = ctx.chart.data.datasets[1].data;

        dataArray1.map(data => {
          amount += data;
        });
        dataArray2.map(data => {
          amount += data;
        });

        let percentage = (value * 100 / amount).toFixed(2);

        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '\n' + parseFloat(percentage).toLocaleString("ru") + '%'; // value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "\n" + percentage + "%";
      }
    }

    let data = {
      labels: response.labels,
      datasets: [
        {
          yAxisID: "bars",
          type: "bar",
          label: "Мужчины",
          stack: "Stack 0",
          data: response.male,
          backgroundColor: '#3366ff',
          hoverBackgroundColor: colors.blue[500],
          borderColor: '#3366ff',
          hoverBorderColor: '#3366ff',
          datalabels: value_with_percentage
        },
        {
          yAxisID: "bars",
          type: "bar",
          label: "Женщины",
          stack: "Stack 1",
          data: response.female,
          backgroundColor: '#f10075',
          hoverBackgroundColor: colors.red[500],
          borderColor: '#f10075',
          hoverBorderColor: '#f10075',
          datalabels: value_with_percentage
        }
      ]
    }

    let options = Object.assign(singleAxes,{legend: {align: 'end'}, maintainAspectRatio: false})

    Chart.helpers.each(Chart.instances, function(instance){
      if (instance.chart.canvas.id === 'chart-ages-count') {
        instance.chart.destroy()
      }
    })
    
    new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: options
    });
  }

  loading(){
    $(this.containerId()).addClass('loading');
  }

  loaded(){
    $(this.containerId()).removeClass('loading');
  }

  elementId(){
    return '#chart-ages-count'
  }

  containerId(){
    return '#chart-ages-count-container'
  }
}

export default ChartAgesCount;
