$(document).ready(function(){
  $('.select2-sendpulse-senders').select2({
    language: 'ru',
    placeholder: 'Выберите отправителя',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: function(){ return $(this).data('url') },
      delay: 500,
      dataType: 'json',
      cache: true,
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(sender, i) {
            // SENDPULSE
            // return { id: sender.email, text: sender.name + ' <' + sender.email + '>', name: sender.name }

            // DEVINO / UNISENDER
            return { id: sender.email, text: sender.email, name: sender.email }
          } ) };
        }
    }
  }).on('select2:select', function(e){
    var data = e.params.data;

    $('#select2-sendpulse-senders-feedback').removeClass('d-block')
    $('#communication_campaign_sender_address').val(data.id);
  });

  $('.select2-project-email').select2({
    language: 'ru',
    placeholder: 'Выберите домен для отправки email писем',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: function(){ return $(this).data('url') },
      delay: 500,
      dataType: 'json',
      cache: true,
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(sender, i) {
            //return { id: `"${sender.email}" <${sender.email}>`, text: sender.email + ' <' + sender.email + '>', name: sender.email }
            return { id: sender.email, text: sender.email, name: sender.email }
          } ) };
        }
    }
  }).on('select2:select', function(e){
    var data = e.params.data;

    $('input#project_email_from').val(`"Имя отправителя" <email@${data.name}>`)
    $('input#project_support_email_from').val(`"Имя отправителя" <support@${data.name}>`)
  });

  $('.select2-project-support-email').select2({
    language: 'ru',
    placeholder: 'Введите имя и почту или оставьте пустым если адрес совпадает с основным адресом E-mail отправителя',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: function(){ return $(this).data('url') },
      delay: 500,
      dataType: 'json',
      cache: true,
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(sender, i) {
            return { id: `"${sender.name}" <${sender.email}>`, text: sender.name + ' <' + sender.email + '>', name: sender.name }
          } ) };
        }
    }
  }).on('select2:select', function(e){
    var data = e.params.data;

    $('#select2-sendpulse-senders-feedback').removeClass('d-block')
    $('#communication_campaign_sender_name').val(data.name);
    $('#communication_campaign_sender_address').val(data.id);
  });

  $('.select2-email-address-books').select2({
    language: 'ru',
    placeholder: 'Выберите проект или сегмент',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: function(){ return $(this).data('url') },
      delay: 500,
      dataType: 'json',
      cache: true,
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(addressable, i) {
            return { id: addressable.id, text: addressable.name, type: addressable.type }
          } ) };
        }
    }
  }).on('select2:select', function(e){
    var data = e.params.data;
    $('#select2-email-address-books-feedback').removeClass('d-block')
    $('#communication_campaign_addressable_type').val(data.type);
    $('#communication_campaign_addressable_id').val(data.id);
  });

  $('#communication_campaign_subject').on('input copy paste', ()=>{
    $('#communication_campaign_subject-feedback').removeClass('d-block')
  })

  $('#new_communication_campaign').find(':input[type=submit]').removeAttr('data-disable-with')

  function checkValidate() {
    if ($('.select2-sendpulse-senders').select2('data')[0].id === "") {
      $('#select2-sendpulse-senders-feedback').addClass('d-block')
    }

    if ($('.select2-email-address-books').select2('data')[0].id === "") {
      $('#select2-email-address-books-feedback').addClass('d-block')
    }

    if (!$('#communication_campaign_subject').val().trim()) {
      $('#communication_campaign_subject-feedback').addClass('d-block')
    }

    if ($('#communication_campaign_subject').val().trim() && $('.select2-email-address-books').select2('data')[0].id !== "" && $('.select2-sendpulse-senders').select2('data')[0].id !== "") {
      $('#new_communication_campaign').submit()
      return true
    } else {
      return false
    }
  }

  $('#new_communication_campaign').submit((e) => {
    if (!checkValidate()) {
      e.preventDefault()
    }

  })
});
