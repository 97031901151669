require('tempusdominus-bootstrap-4');
require('src/inputmask');
import Inputmask from 'src/inputmask';

class Datetimepicker {
  constructor() {
  }

  static onInit() {
    $('[data-toggle="datetimepicker"]').each(function(index){
      var target = $(this).data('target')
      var showTimepicker = false
      var format = 'YYYY-MM-DD'

      // if time needed use data-type=datetime
      if ($(this).data('type') == 'datetime'){
        showTimepicker = true
        format = 'YYYY-MM-DD HH:mm O'
      } else if ($(this).data('type') == 'date') {
        showTimepicker = false
        format = 'YYYY-MM-DD'
      }

      if($(this).hasClass('datetimeseconds'))
      {
        format = 'YYYY-MM-DD HH:mm:ss O'
      }

      $(target).datetimepicker({
        locale: 'ru',
        format: format,
        ignoreReadonly: true,
        showTimepicker: showTimepicker,
        buttons: {
          showClose: true
        },
        icons: {
          time: 'fas fa-clock',
          date: 'fas fa-calendar',
          up: 'fas fa-arrow-up',
          down: 'fas fa-arrow-down',
          previous: 'fas fa-chevron-left',
          next: 'fas fa-chevron-right',
          today: 'fas fa-calendar-check',
          clear: 'fas fa-delete',
          close: 'fas fa-times'
        }
      });
    });
    $('.datetime-mask').inputmask("9999-99-99 99:99")
    $('.datetimeseconds-mask').inputmask("9999-99-99 99:99:99")
    $('.date-mask').inputmask("9999-99-99")
  }
}

export default Datetimepicker;
