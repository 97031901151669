$(document).ready(function(){
  $('#edit_project_periods').on('cocoon:after-insert', function(e, insertedItem, originalEvent) {
    Datetimepicker.onInit();
  });

  $('.select2-retailers').select2({
    language: {
      noResults: function(){
        return 'Ритейлер не найден';
      }
    },
    placeholder: 'Укажите список ритейлеров для отчета по сетям',
    searchInputPlaceholder: 'Поиск',
    width: '100%',
    ajax: {
      url: '/api/v1/retailers.json',
      dataType: 'json',
      processResults: function (data, page) {
          return { results: JQuery.map(data, function(retailer, i) {
            return { id: retailer.id, text: retailer.name }
          } ) };
        }
    }
  });
});
