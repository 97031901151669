import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class ReceiptsModerationStatus {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__receipts_moderation_status');
    let check = $('canvas').is('#charts__receipts_moderation_status');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/receipts_moderation_status?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        //if (response.data[0] == 0 && response.data[1] == 0){
        //  $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
        //  return;
        //}

        let data = {
          labels: response.labels,

          datasets: [
            {
              data: response.data,
              backgroundColor: [ colors.green[300], colors.red[300], colors.purple[300], colors.blue[300], colors.orange[300] ],
              hoverBackgroundColor: [ colors.green[500], colors.red[500], colors.purple[500], colors.blue[500], colors.orange[500] ],
              borderColor: [ colors.green[500], colors.red[500], colors.purple[500], colors.blue[500], colors.orange[500] ],
              hoverBorderColor: [ colors.green[500], colors.red[500], colors.purple[500], colors.blue[500], colors.orange[500] ],
              fill: true,
              datalabels: {
                color: [colors.black.primary, colors.white.primary]
              }
            }
          ]
        };

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});
        options['legend']['display'] = false

        options['legendCallback'] =   function(chart) {
          let currentDataset = chart.data.datasets[0];
          let dataSum = currentDataset.data.reduce((a, b) => a + b, 0);

          for (let i = 0; i < currentDataset.data.length; i++) {
            let iconColor = currentDataset.backgroundColor[i];

            let value = currentDataset.data[i];
            let valueString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            let percentage = (value * 100 / dataSum).toFixed(2);

            $('.card-body .js-receipts-moderation-status').eq(i).find('.circle-media-body .count').text(valueString);
            $('.card-body .js-receipts-moderation-status').eq(i).find('.circle-media-body .percentage').text('(' + percentage + '%)');
            $('.card-body .js-receipts-moderation-status').eq(i).find('.circle-media-icon').css('color', iconColor);
          }
        }

        let incorrectReceiptsChart1 = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
        incorrectReceiptsChart1.generateLegend();
      }
    }
  }

  static onInit() {
    ReceiptsModerationStatus.Chart();
  }
}

export default ReceiptsModerationStatus;
