import $ from 'jquery';

class cdpStatisticsAverageSpentTime{
  constructor() {
    if ($(this.elementId()).length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;

    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $(cdpStat.elementId()).data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $(this.elementId() + ' h6 span.value').text(response.average_value);
    this.loaded();
  }

  loading(){
    $(this.elementId()).addClass('loading');
  }

  loaded(){
    $(this.elementId()).removeClass('loading');
  }

  elementId(){
    return '#cdp-stat-average-spent-time'
  }
}

export default cdpStatisticsAverageSpentTime;
