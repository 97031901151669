import $ from 'jquery';

class PrizeDistribution{
  constructor() {
    if ($('.card.prize_distribution').length > 0){
      this.init()
    }
  }

  init(){
    this.init_table()
  }

  init_table(){
    var columns = [
      { 'data': 'participant_first_name', orderable: true },
      { 'data': 'participant_last_name', orderable: true },
      { 'data': 'participant_middle_name', orderable: true },
      { 'data': 'email', orderable: true },
      { 'data': 'prize', orderable: true },
      { 'data': 'promocode', orderable: true },
      { 'data': 'winner_created_at', orderable: true },
      { 'data': 'issued_at', orderable: true },

    ]

    if ($('#prize_distribution_table').data('role') == 'editor')
      columns.push({ 'data': 'distribute_prize', orderable: false })

    this.table = $('#prize_distribution_table').DataTable({
      'searching': false,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'ordering': true,
      'order': [[0, 'desc']],
      // # NOTE: Change also set of columns in controller
      'columns': columns,
      'ajax': {
        'url': $('#deliveries_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  update_table(){
    this.table.draw(false);
  }

  issue(input){
    if (confirm('Вы уверены?'))
      Rails.fire(input.form, "submit")
    else
      $(input).prop('checked', !$(input).prop('checked'))
  }
}

export default PrizeDistribution;