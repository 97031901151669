import $ from 'jquery';

class cdpInsightMostPopularContragents{
  constructor() {
    if ($('#cdp-insight-most-popular-contragents').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-insight-most-popular-contragents').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    if (response.profiles_count == 0) {
      $('#cdp-insight-most-popular-contragents #one_uid').removeClass('d-none')
      $('#cdp-insight-most-popular-contragents #two_uid').addClass('d-none')
      $('#cdp-insight-most-popular-contragents #one_uid').html("Нет данных")
      $('#cdp-insight-most-popular-contragents .card-body .ml-2 h4').text(this.transform(response.profiles_count));
      $('#cdp-insight-most-popular-contragents .peity-donut').text(`${response.profiles_percentage}/100`);
      $('#cdp-insight-most-popular-contragents .peity-donut').peity('donut');
    }else{
      if (response.similarity < 85) {
        $('#cdp-insight-most-popular-contragents #one_uid').removeClass('d-none')
        $('#cdp-insight-most-popular-contragents #two_uid').addClass('d-none')

        $('#cdp-insight-most-popular-contragents #percentage').text(this.transform(response.profiles_percentage) + '%');
        $('#cdp-insight-most-popular-contragents #contragent_link').text(`«${response.contragent_name_first}»`);
        $('#cdp-insight-most-popular-contragents #contragent_link').attr('href', `${location.origin}/contragents/${response.contragent_uid_first}`);
        $('#cdp-insight-most-popular-contragents #contragent_link').attr('target', '_blank');
        $('#cdp-insight-most-popular-contragents #average_sum').text(this.transform(response.average_sum));
        $('#cdp-insight-most-popular-contragents #average_products_count').text(this.transform(response.average_products_count));
        $('#cdp-insight-most-popular-contragents .card-body .ml-2 h4').text(this.transform(response.profiles_count));
        $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').attr('data-scope', `with_contragents`);
        $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').attr('data-args', `${response.contragent_uid_first},`);
        $('#cdp-insight-most-popular-contragents .peity-donut').text(`${response.profiles_percentage}/100`);
        $('#cdp-insight-most-popular-contragents .peity-donut').peity('donut');
      } else {
        $('#cdp-insight-most-popular-contragents #one_uid').addClass('d-none')
        $('#cdp-insight-most-popular-contragents #two_uid').removeClass('d-none')

        $('#cdp-insight-most-popular-contragents #promo_link').text(`«${response.promo_name}»`);
        $('#cdp-insight-most-popular-contragents #promo_link').attr('href', `${location.origin}/production/projects/${response.promo_id}`);
        $('#cdp-insight-most-popular-contragents #promo_link').attr('target', '_blank');
        $('#cdp-insight-most-popular-contragents #profiles_count').text(this.transform(response.profiles_count));
        $('#cdp-insight-most-popular-contragents #contragent_link_first').text(`«${response.contragent_name_first}»`);
        $('#cdp-insight-most-popular-contragents #contragent_link_first').attr('href', `${location.origin}/contragents/${response.contragent_uid_first}`);
        $('#cdp-insight-most-popular-contragents #contragent_link_first').attr('target', '_blank');
        $('#cdp-insight-most-popular-contragents #contragent_link_second').text(`«${response.contragent_name_second}»`);
        $('#cdp-insight-most-popular-contragents #contragent_link_second').attr('href', `${location.origin}/contragents/${response.contragent_uid_second}`);
        $('#cdp-insight-most-popular-contragents #contragent_link_second').attr('target', '_blank');
        $('#cdp-insight-most-popular-contragents #receipts_count_first').text(this.transform(response.receipts_count_first));
        $('#cdp-insight-most-popular-contragents #receipts_count_second').text(this.transform(response.receipts_count_second));
        $('#cdp-insight-most-popular-contragents .card-body .ml-2 h4').text(this.transform(response.profiles_count));
        $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').attr('data-scope', `with_contragents`);
        $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').attr('data-args', `${response.contragent_uid_first},${response.contragent_uid_second}`);
        $('#cdp-insight-most-popular-contragents .peity-donut').text(`${response.profiles_percentage}/100`);
        $('#cdp-insight-most-popular-contragents .peity-donut').peity('donut');
      }
    }
    this.loaded();
    this.toggleActionBtn(response.profiles_count);
  }

  toggleActionBtn(profiles_count){
    if (profiles_count > 0){
      $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').removeClass('d-none');
    } else {
      $('#cdp-insight-most-popular-contragents .open-new-insight-segment-modal').addClass('d-none');
    }
  }

  loading(){
    $('#cdp-insight-most-popular-contragents').addClass('loading');
  }

  loaded(){
    $('#cdp-insight-most-popular-contragents').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpInsightMostPopularContragents;