import $ from 'jquery';

class sidebarNav {
  constructor() {
    if ($('#sidebarNav')) {
      this.init()
    }
  }

  init() {
    this.sidebarNav()
  }

  sidebarNav() {
    $('#sidebarNav .active').parent().addClass('active').parent().parent().addClass('show')
  }

}

export default sidebarNav;