import $ from 'jquery';

import cdpStatisticsProfilesWithEmail from './statistics/profiles_with_email';
import cdpStatisticsProfilesWithPhone from './statistics/profiles_with_phone';
import cdpStatisticsProfilesWithFingerprint from './statistics/profiles_with_fingerprint';
import cdpStatisticsProfilesWithVk from './statistics/profiles_with_vk';
import cdpStatisticsProfilesWithOk from './statistics/profiles_with_ok';
import cdpStatisticsProfilesWithFb from './statistics/profiles_with_fb';
import cdpStatisticsProfilesWithMyTarget from './statistics/profiles_with_my_target';
import cdpStatisticsProfilesWithYandex from './statistics/profiles_with_yandex';
import cdpStatisticsAverageSpentTime from './statistics/average_spent_time';
import ChartGendersCount from './statistics/chart_genders_count';
import ChartAgesCount from './statistics/chart_ages_count';
import cdpStatisticsDeviseKind from './statistics/devise_kind';
import cdpStatisticsMostPopularCitiesOnline from './statistics/most_popular_cities_online';
import cdpStatisticsActiveParticipants from './statistics/active_participants';
import cdpStatisticsNewParticipants from './statistics/new_participants';
import cdpStatisticsActiveParticipantsWithreceipts from './statistics/active_participants_with_receipts';
import cdpStatisticsMostPopularCitiesPeriod from './statistics/most_popular_cities_period'
import cdpStatisticsActivityStats from './statistics/activity_stats';

class cdpStatistics{
  constructor() {
    if ($('#cdp-profiles-statistics').length > 0){
      this.init()
    }
  }

  init(){
    new cdpStatisticsProfilesWithEmail()
    new cdpStatisticsProfilesWithPhone()
    new cdpStatisticsProfilesWithFingerprint()
    new cdpStatisticsProfilesWithFb()
    new cdpStatisticsProfilesWithVk()
    new cdpStatisticsProfilesWithOk()
    new cdpStatisticsProfilesWithMyTarget()
    new cdpStatisticsProfilesWithYandex()
    new cdpStatisticsAverageSpentTime()
    new ChartGendersCount()
    new ChartAgesCount()
    new cdpStatisticsMostPopularCitiesOnline()
    new cdpStatisticsDeviseKind()
    new cdpStatisticsActiveParticipants()
    new cdpStatisticsNewParticipants()
    new cdpStatisticsActiveParticipantsWithreceipts()
    new cdpStatisticsMostPopularCitiesPeriod()
    new cdpStatisticsActivityStats()
  }
}

export default cdpStatistics;
