import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, value } from '../options';

class RegistedReceiptsOnMemberWeekly {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-receipts-on-member-weekly')
    let check = $('canvas').is('#charts__registed-receipts-on-member-weekly')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    let datasets = []
    let options = Object.assign({}, doubleAxes)
    let numbers

    if(check) {
      $.ajax({
        url: `/api/v1/statistics/receipts_by_participant_weekly?project_id=${project_id}&env=${env}` + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let actual_dataset = {
          yAxisID: "lines",
          type: "line",
          label: "Количество",
          data: response.average_actual,
          backgroundColor: colors.blue[300],
          hoverBackgroundColor: colors.blue[500],
          borderColor: colors.blue[500],
          hoverBorderColor: colors.blue[500],
          hoverPointBackgroundColor: colors.red[500],
          pointBorderColor: colors.red[500],
          hoverPointBorderColor: colors.red[500],
          fill: false,
          datalabels: value
        }

        datasets.push(actual_dataset)

        if (response.average_plan){
          let plan_datasets = {
            yAxisID: "lines",
            type: "line",
            label: "План",
            data: response.average_plan,
            backgroundColor: colors.red[300],
            hoverBackgroundColor: colors.red[500],
            borderColor: colors.red[500],
            hoverBorderColor: colors.red[500],
            pointBackgroundColor: colors.red[300],
            hoverPointBackgroundColor: colors.red[500],
            pointBorderColor: colors.red[500],
            hoverPointBorderColor: colors.red[500],
            fill: false,
            datalabels: value
          }
          datasets.push(plan_datasets)

          numbers = response.average_plan.concat(response.average_actual)
        } else {
          numbers = response.average_actual
        }

        var maxTicks = Math.max(...numbers) / 100
        maxTicks = Math.ceil(maxTicks) * 100;
        options['scales']['yAxes'][1]['ticks']['max'] = maxTicks
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value) + '%'
        }

        let data = {
          labels: response.labels,
          datasets: datasets
        };

        let registedReceiptsOnMemberWeeklyChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });

        let showValue = true
        let newValueOptions = {}

        $('#registed-receipts-on-member-weekly-switch').on('click', function(){
          $(this).toggleClass('on')

          if(showValue == true)
          {
            showValue = false
            newValueOptions = {}
          }
          else
          {
            showValue = true
            newValueOptions = value
          }

          data.datasets.forEach(function(dataset) {
            dataset['datalabels'] = newValueOptions
          })

          registedReceiptsOnMemberWeeklyChart.update()
        })
      }
    }
  }

  static onInit() {
    RegistedReceiptsOnMemberWeekly.Chart();
  }
}

export default RegistedReceiptsOnMemberWeekly;
