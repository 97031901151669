import $ from 'jquery';

class segmentModals {
  constructor() {
    $('#segmentFiltersToggleButton').click(function(){
      $('.modal').modal('hide');
      $('#edit-filters-segment-modal').modal('show')
    })
    $('.segmentSaveSuccessSegmentToggleButton').click(function(){
      if(!this.classList.contains('disabled') && this.classList.contains('segmentSaveSuccessSegmentToggleButton') ){
        $('.modal').modal('hide');
        $('#save-success-segment-modal').modal('show')
      }
    })
    $('.open-persisted-segment-modal').click(function(){
      $('.modal').modal('hide');
      $('#save-persisted-segment-modal').modal('show')
      $('#segment-query').val(JSON.stringify(SegmentQueryBuilder.getMongoQuery()))
    })
    $('.open-cdp-export-modal').click(function(){
      $('.modal').modal('hide');
      $('#cdp-profiles-export-modal').modal('show');
      $('#cdp-profiles-export-modal form').attr('action', $(this).data("href"));
      if (SegmentQueryBuilder.exists()){
        $('#cdp-profiles-export-query').val(JSON.stringify(SegmentQueryBuilder.getMongoQuery()));
      }
      $('.open-cdp-export-modal').css('background-color', '#97A3B8')
      $('head').append('<style id="temporary-style-for-open-cdp-export-modal">#export-button-group > .open-cdp-export-modal:before{filter: brightness(100);}</style>');
      setTimeout(function(){
        $('.open-cdp-export-modal').css('background-color', '#CDD4DE')
        $('#temporary-style-for-open-cdp-export-modal').remove();
      }, 500)
    })
    $('.delete-segment-button').click(function(){
      $('.modal').modal('hide');
      $('#delete-segment-modal').modal('show')
    })
    $('#segmentNameInput').on("change paste keyup", function() {
      if($(this).val().trim()===""){
        $('#segmentSaveToNewButton')
            .removeClass('btn-success')
            .addClass('btn-secondary disabled').attr('disabled', 'disabled');
        $('#segmentSaveButton')
            .removeClass('btn-success segmentSaveSuccessSegmentToggleButton')
            .addClass('btn-secondary disabled').attr('disabled', 'disabled');
      } else {
        $('#segmentSaveToNewButton')
            .addClass('btn-success')
            .removeClass('btn-secondary disabled').attr('disabled', false);
        $('#segmentSaveButton')
            .addClass('btn-success segmentSaveSuccessSegmentToggleButton')
            .removeClass('btn-secondary disabled').attr('disabled', false);
      }
    });
    $('#segmentSaveButton').click(function(){
      $('#segment-form').prepend('<input type="hidden" name="_method" value="patch">')
      $('#segment-form').attr('action', $('#segmentSaveButton').data('url'))
    })
    $('#segmentSaveToNewButton').click(function(){
      $('#segment-form').attr('action', $('#segmentSaveToNewButton').data('url'))
    })
  }
}

export default segmentModals;