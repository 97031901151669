import $ from 'jquery';

class communicationPreview {
  constructor() {
    setInterval(()=>{
      $('.emailTemplateIframePreview').css({
        height: $('.emailTemplateIframePreview').width() +'px'
      })
    }, 100)
  }

}

export default communicationPreview;