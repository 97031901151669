import $ from 'jquery';

class cdpStatisticsProfilesWithOk{
  constructor() {
    if ($('#cdp-stat-profiles-with-ok').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-stat-profiles-with-ok').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $('#cdp-stat-profiles-with-ok h6').text(`${this.transform(response.profiles_count)} (${this.transform(response.profiles_percentage)}%)`);
    this.loaded();
  }

  loading(){
    $('#cdp-stat-profiles-with-ok').addClass('loading');
  }

  loaded(){
    $('#cdp-stat-profiles-with-ok').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsProfilesWithOk;