import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class ProductPopularity2 {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__product-popularity_2');
    let check = $('canvas').is('#charts__product-popularity_2');
    let project_id = $('[data-project-id]').data('project-id');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/valid_codes_by_products?project_id=' + project_id + '&start_week=' + first_week + '&end_week=' + end_week,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.data[0] == 0 && response.data[1] == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,

          datasets: [
            {
              data: response.data,
              backgroundColor: [ colors.blue[300], colors.red[300], colors.orange[300], colors.green[300] ],
              hoverBackgroundColor: [ colors.blue[500], colors.red[500], colors.orange[500], colors.green[500] ],
              borderColor: [ colors.blue[500], colors.red[500], colors.orange[500], colors.green[500] ],
              hoverBorderColor: [ colors.blue[500], colors.red[500], colors.orange[500], colors.green[500] ],
              fill: true,
              datalabels: {
                color: [colors.white.primary, colors.white.primary, colors.black.primary, colors.white.primary]
              }
            }
          ]
        }

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    ProductPopularity2.Chart();
  }
}

export default ProductPopularity2;
