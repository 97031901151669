import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, value, percentage } from '../options';

class ParticipantsReceipts {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__participants-receipts');
    let check = $('canvas').is('#charts__participants-receipts');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/participants_receipts?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels,

          datasets: [
            {
              yAxisID: "bars",
              type: "line",
              label: "Количество валидных чеков",
              data: response.receipts,
              backgroundColor: colors.green[300],
              hoverBackgroundColor: colors.green[500],
              borderColor: colors.green[500],
              hoverBorderColor: colors.green[500],
              fill: false,
              datalabels: value
            },
            {
              yAxisID: "bars",
              type: "line",
              label: "Количество участников",
              data: response.participants,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500],
              fill: false,
              datalabels: value
            }
          ]
        };

        let participantsReceiptsChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: singleAxes
        });

        let showValue = true
        let newValueOptions = {}

        $('#participants-receipts-switch').on('click', function(){
          $(this).toggleClass('on')

          if(showValue == true)
          {
            showValue = false
            newValueOptions = {}
          }
          else
          {
            showValue = true
            newValueOptions = value
          }

          data.datasets.forEach(function(dataset) {
            dataset['datalabels'] = newValueOptions
          })

          participantsReceiptsChart.update()
        })
      }
    }
  }

  static onInit() {
    ParticipantsReceipts.Chart();
  }
}

export default ParticipantsReceipts;
