import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class Behaviours {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__behaviours');
    if (ctx.length == 0) return;

    let project_id = $('[data-project-id]').data('project-id')
    $.ajax({
      url: '/api/v1/statistics/behaviours?project_id=' + project_id,
      method: 'GET',
      dataType: 'json',
      success: function (response) {
        initChart(response)
      }
    });

    let initChart = function(response){
      let left = $('<div class="data_left"></div>')
      let right = $('<div class="data_right"></div>')
      for(let i=0; i< response.labels.length; i++){
        let label = $('<div class="data_label"></div>')
        label.html(response.labels[i])
        let value = $('<div class="data_value"></div>')
        let valueData = response.data[i]
        if (valueData.indexOf('.') >= 0){
          valueData = Number(parseFloat(response.data[i]).toFixed(2));
        }else if (valueData.indexOf(':') == -1){
          valueData = valueData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        }
        value.html(valueData)

        if (i % 2 == 0){
          left.append(label)
          left.append(value)
        } else {
          right.append(label)
          right.append(value)
        }
      }
      ctx.append(left)
      ctx.append(right)
    }
  }

  static onInit() {
    Behaviours.Chart();
  }
}

export default Behaviours;
