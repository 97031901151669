import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class Audience1 {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__audience_1');
    let check = $('canvas').is('#charts__audience_1');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/participants_gender?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.data[0] == 0 && response.data[1] == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: [ colors.blue[300], colors.red[300] ],
              hoverBackgroundColor: [ colors.blue[500], colors.red[500] ],
              borderColor: [ colors.blue[500], colors.red[500] ],
              hoverBorderColor: [ colors.blue[500], colors.red[500] ],
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    Audience1.Chart();
  }
}

export default Audience1;
