import $ from 'jquery';

class cdpStatisticsMostPopularCitiesPeriod{
  constructor() {
    if ($('#cdp-stat-most-popular-cities-period').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-stat-most-popular-cities-period').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    var i = 0
    response.top_cities_with_data.forEach((e) => {
      $(`#cities-group #item-${i} #city-name-${i}`).text(`${e.city}`)
      $(`#cities-group #item-${i} #city-count-${i}`).text(`${this.transform(e.count)} (${this.transform(e.profiles_percentage)}%)`)
      $(`#cities-group #item-${i} .progress-bar`).css('width', `${e.profiles_percentage}%`)

      $(`#cities-group #item-${i} .progress-bar`).attr('aria-valuenow', Math.ceil(e.profiles_percentage))

      i++
    })
    
    this.renderMap(response);
    this.loaded();
  }

  renderMap(response){
    $('#vmap').empty()
    $('#vmap').vectorMap({
      map: 'ru_mill',
      showTooltip: true,
      backgroundColor: '#f8f9fa',
      enableZoom: false,
      borderOpacity: .3,
      borderWidth: 3,
      markers: response.all_cities,
      regionStyle: {
        initial: {
          fill: '#ced4da',
          "fill-opacity": 1,
          stroke: 'none',
          "stroke-width": 0,
          "stroke-opacity": 1
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: 'pointer'
        }
      },
      onMarkerTipShow: function(event, label, index){
        label.html(
          `<b>${response.all_cities[index].name}</b> - ${parseFloat(response.all_cities[index].count).toLocaleString("ru")} (${parseFloat(response.all_cities[index].profiles_percentage).toLocaleString("ru")}%)` 
        );
      },
    });
  }

  loading(){
    $('#cdp-stat-most-popular-cities-period').addClass('loading');
  }

  loaded(){
    $('#cdp-stat-most-popular-cities-period').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsMostPopularCitiesPeriod;