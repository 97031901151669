import $ from 'jquery';
require('../jquery.flot')
require('../jquery.flot.resize')
import {dashData1, dashData2, dashData3, dashData4} from "../dashboard.sampledata";
import {colors} from "../charts/colors";
import {pie, singleAxes, value} from "../charts/options";

class allProfilesStatisticsSampleData {
  constructor() {
    console.log()
    if($('#all_profiles_statistics').length){
      this.init()
    }

  }

  init() {
    $.plot('#flotChart1', [{
      data: dashData1,
      color: '#6f42c1'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart2', [{
      data: dashData2,
      color: '#007bff'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart3', [{
      data: dashData3,
      color: '#f10075'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart4', [{
      data: dashData4,
      color: '#00cccc'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart5', [{
      data: dashData1,
      color: '#6f42c1'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart6', [{
      data: dashData2,
      color: '#007bff'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart7', [{
      data: dashData3,
      color: '#f10075'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart8', [{
      data: dashData3,
      color: '#FC3F1D'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart9', [{
      data: dashData4,
      color: '#00cccc'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart10', [{
      data: dashData4,
      color: '#F7931E'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart11', [{
      data: dashData4,
      color: '#2787F5'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });

    $.plot('#flotChart12', [{
      data: dashData4,
      color: '#157DC3'
    }], {
      series: {
        shadowSize: 0,
        lines: {
          show: true,
          lineWidth: 2,
          fill: true,
          fillColor: { colors: [ { opacity: 0 }, { opacity: 1 } ] }
        }
      },
      grid: {
        borderWidth: 0,
        labelMargin: 0
      },
      yaxis: {
        show: false,
        min: 0,
        max: 100
      },
      xaxis: { show: false }
    });


    $('#vmap').vectorMap({
      map: 'ru_mill',
      showTooltip: true,
      backgroundColor: '#f8f9fa',
      enableZoom: false,
      borderOpacity: .3,
      borderWidth: 3,
      regionStyle: {
        initial: {
          fill: '#ced4da',
          "fill-opacity": 1,
          stroke: 'none',
          "stroke-width": 0,
          "stroke-opacity": 1
        },
        hover: {
          "fill-opacity": 0.8,
          cursor: 'pointer'
        }
      }
    });
  }
}

export default allProfilesStatisticsSampleData;