$(document).ready(function(){
  Tickets.submitOnKeydown();
  Tickets.disableOnSubmit();
});

window.Tickets = {
  submitForm: function(){
    var text = $('#support_message_body').val();
    var textPresent = (text !== '');

    if(textPresent && confirm('Вы уверены?'))
    {
      let form = document.getElementById('support_chat_form');
      Rails.fire(form, 'submit');
    }
  },

  setAsComment: function(){
    var is_comment = $('#support_message_comment').val()
    
    if(is_comment == 'true'){
      $('#support_message_comment').val('false')
      $('#comment_button').removeClass('fas')
      $('#comment_button').addClass('far')
    }else{
      $('#support_message_comment').val('true')
      $('#comment_button').removeClass('far')
      $('#comment_button').addClass('fas')
    }

    $('#comment_button').tooltip('hide')
  },

  submitOnKeydown: function(){
    $('#support_chat_form').unbind('keydown');
    $('#support_chat_form').keydown(function (e) {
      var submitKey = (e.ctrlKey && e.keyCode == 13);

      if(submitKey) {
        Tickets.submitForm()
      }
    });
  },

  disableOnSubmit: function(){
    $('#support_chat_form').submit(function(){
      Tickets.disableSubmitButton()
    });
  },

  disableSubmitButton: function(){
    var submitButton = $('#support_chat_form a.far.fa-paper-plane');
    submitButton.css({'color': '#ccc'});
    submitButton.attr('onclick', null);
  },

  enableSubmitButton: function(){
    var submitButton = $('#support_chat_form a.far.fa-paper-plane');
    submitButton.attr('style', null);
    submitButton.attr('onclick', 'Tickets.submitForm()');
  }
}
