import $ from 'jquery';


class GeoCodes {
  constructor() {
  }

  static Chart() {
    let ctx = $('#map_geo_codes');
    if (ctx.length == 0) return;

    let project_id = $('[data-project-id]').data('project-id')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    $.ajax({
      url: `/api/v1/statistics/geo_codes?project_id=${project_id}` + periodParams,
      method: 'GET',
      dataType: 'json',
      success: function (response) {
        initChart(response)
      }
    });

    let initChart = function(response){
      if (Object.keys(response.grouped_by_regions).length == 0){
        $(ctx).parent().html('<div class="not_enough_data">Для отображения недостаточно данных</div>')
        return;
      }

      $('#map_geo_codes').vectorMap({
        map: 'ru_mill',
        markerStyle: {
          initial: {
            fill: '#304FFE',
            stroke: '#bbdefb'
          }
        },
        markers: response.grouped_by_cities,
        backgroundColor: '#006994',
        series: {
          regions: [{
            scale: ['#DEEBF7', '#08519C'],
            attribute: 'fill',
            values: response.grouped_by_regions,
            legend: { vertical: true }
          }],
          markers: [{
            attribute: 'fill',
            scale: ['#ff80ab', '#b71c1c'],
            values: response.marker_values,
            legend: { horizontal: true }
          },{
            attribute: 'r',
            scale: [5, 10],
            values: response.marker_values,
          }]
        },
        onMarkerTipShow: function(event, label, index){
          label.html(
            '<b>'+response.grouped_by_cities[index].name + '</b> - '+response.marker_values[index]
          );
        },
        onRegionTipShow: function(e, el, code){
          let hint = el.html()
          if(response.grouped_by_regions[code])
            hint = el.html() + ' - ' + response.grouped_by_regions[code]
          el.html(hint);
        }
      })

      let table = $('#codes_table')
      table.html('')
      for(let i = 0; i < response.grouped_by_cities.length; i++){
        let tr = $('<tr></tr>')
        let name = $('<td></td>')
        name.text(response.grouped_by_cities[i].name)
        let value = $('<td></td>')
        value.text(response.marker_values[i])
        tr.append(name)
        tr.append(value)
        table.append(tr)
      }
    }
  }

  static onInit() {
    GeoCodes.Chart();
  }
}

export default GeoCodes;
