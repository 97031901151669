import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes } from '../options';

class Audience2 {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__audience_2');
    let check = $('canvas').is('#charts__audience_2');
    let project_id = $('[data-project-id]').data('project-id');
    let env = $('[data-env]').data('env');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/participants_gender_by_age?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let value_with_percentage = {
        color: colors.white.primary,
        backgroundColor: colors.orange[500],
        padding: 1,
        font: {
          size: 9
        },
        textAlign: 'center',
        formatter: function(value, ctx) {
          let amount = 0;
          let dataArray1 = ctx.chart.data.datasets[0].data;
          let dataArray2 = ctx.chart.data.datasets[1].data;

          dataArray1.map(data => {
            amount += data;
          });
          dataArray2.map(data => {
            amount += data;
          });

          let percentage = (value * 100 / amount).toFixed(2);

          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + '\n' + percentage + '%'; // value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "\n" + percentage + "%";
        }
      }

      let initChart = function(response){
        if (response.male.reduce((a, b) => a + b, 0) == 0 && response.female.reduce((a, b) => a + b, 0) == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              yAxisID: "bars",
              type: "bar",
              label: "Мужчины",
              stack: "Stack 0",
              data: response.male,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500],
              datalabels: value_with_percentage
            },
            {
              yAxisID: "bars",
              type: "bar",
              label: "Женщины",
              stack: "Stack 1",
              data: response.female,
              backgroundColor: colors.red[300],
              hoverBackgroundColor: colors.red[500],
              borderColor: colors.red[500],
              hoverBorderColor: colors.red[500],
              datalabels: value_with_percentage
            }
          ]
        };

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: singleAxes
        });
      }
    }
  }

  static onInit() {
    Audience2.Chart();
  }
}

export default Audience2;
