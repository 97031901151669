import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class Feedbacks {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__feedbacks');
    let check = $('canvas').is('#charts__feedbacks');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/feedbacks?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let colors_300 = []
        let colors_500 = []
        colors.reset()
        for(let label in response.labels){
          let color = colors.getColor()
          colors_300.push(color[300])
          colors_500.push(color[500])
          colors.next()
        }

        let data = {
          labels: response.labels,
          datasets: [
            {
              data: response.data,
              backgroundColor: colors_300,
              hoverBackgroundColor: colors_500,
              borderColor: colors_500,
              hoverBorderColor: colors_500,
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    Feedbacks.Chart();
  }
}

export default Feedbacks;
