import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, value } from '../options';

class TotalCostReceipts {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__total-cost-receipts')
    let check = $('canvas').is('#charts__total-cost-receipts')
    let project_id = $('[data-project-id]').data('project-id')
    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let env = $('[data-env]').data('env')
    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/receipts_total_cost?project_id=' + project_id + '&start_week=' + first_week + '&end_week=' + end_week + '&env=' + env,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.tickets_cost.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let total_dataset = {
          yAxisID: "bars",
          type: "line",
          label: "Общая стоимость промо",
          data: response.total_cost,
          backgroundColor: colors.green[300],
          hoverBackgroundColor: colors.green[500],
          borderColor: colors.green[500],
          hoverBorderColor: colors.green[500],
          fill: false,
          datalabels: value
        }

        datasets.push(total_dataset)

        let cost_dataset = {
          yAxisID: "bars",
          type: "line",
          label: "Окупаемость",
          data: response.tickets_cost,
          backgroundColor: colors.yellow[300],
          hoverBackgroundColor: colors.yellow[500],
          borderColor: colors.yellow[500],
          hoverBorderColor: colors.yellow[500],
          fill: false,
          datalabels: value
        }

        datasets.push(cost_dataset)

        let data = {
          labels: response.labels,
          datasets: datasets
        };

        let newChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    TotalCostReceipts.Chart();
  }
}

export default TotalCostReceipts;
