$(document).ready(function(){
  $('.select2-tags').each(function() {
    var tags = $(this).data('tags');
    if(typeof tags === 'undefined') { tags = true }
    $(this).select2({
      language: 'ru',
      placeholder: 'Задайте список меток (торговые сети, бренды и др.)',
      searchInputPlaceholder: 'Поиск',
      width: '100%',
      // minimumInputLength: 2,
      tags: tags,
      ajax: {
        url: '/tags/projects.json',
        delay: 500,
        dataType: 'json',
        processResults: function (data, page) {
            return { results: JQuery.map(data, function(tag, i) {
              return { id: tag.name, text: tag.name }
            } ) };
          }
      }
    });
  });
});