import $ from 'jquery';

class tableAdaptive {
  constructor() {
    if ($('#table-responsive')) {
      this.init()
    }
  }

  init() {
    setInterval(()=>{
      let tableWidth = $('table.table-responsive').children('thead').width()
      let tableParentWidth = $('table.table-responsive').parent().width()
      if(tableWidth > tableParentWidth){
        $('table.table-responsive').css('display','block')
      } else {
        $('table.table-responsive').css('display','table')
      }
    }, 100)
  }

}

export default tableAdaptive;