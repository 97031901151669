import $ from 'jquery';

class cdpInsightPotentialReceipts {
  constructor() {
    if ($(this.elementId()).length > 0){
      this.loading()
      this.init()
    }
  }

  init() {
    var cdpStat = this;

    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };

        $.ajax({
          url: $(cdpStat.elementId()).data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $(this.elementId() + ' .card-body .card-body h4').text(this.transform(response.profiles_count));
    $(this.elementId() + ' .card-body .card-text').html(this.formText(response));
    $(this.elementId() + ' .peity-donut').text(`${response.profiles_percentage}/100`);
    $(this.elementId() + ' .peity-donut').peity('donut');

    this.loaded();
    this.toggleActionBtn(response.profiles_count);
  }

  loading(){
    $(this.elementId()).addClass('loading');
  }

  loaded(){
    $(this.elementId()).removeClass('loading');
  }

  formText(response){
    if(response.profiles_count > 0)
    {
      var text = "<b>" +
                 this.transform(response.profiles_count) + ", (" + this.transform(response.profiles_percentage) + "%) "
                 + "</b>" +
                 "участников в этом сегменте участвовали более чем в одном промо."
    }
    else
    {
      var text = ''
    }

    return text
  }

  toggleActionBtn(profiles_count){
    if (profiles_count > 0){
      $(this.elementId() + ' .segment-action-btn').removeClass('d-none');
    } else {
      $(this.elementId() + ' .segment-action-btn').addClass('d-none');
    }
  }

  elementId() {
    return "#cdp-insight-potential-participants"
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpInsightPotentialReceipts;
