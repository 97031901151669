import $ from 'jquery';

class userNewAccess {
  constructor() {
    if($('.user_new_access').length){
      this.init()
    }
  }

  init() {
    $('.user_new_section_access').on('click', ()=>{
      $('.az-toggle').on('click', function(){
        $(this).toggleClass('on');
      })
      $('#user_new_section_access_modal').modal('show');
    })
    $( document ).on( "newAccessModal", function( ) {

      $('.az-toggle').on('click', function(){
        $(this).toggleClass('on');
      })

      $('.user-accesses-switcher').on('click', (e)=>{
        let isAddAccess = e.currentTarget.classList.contains('on')

        let access = {
          accessible_id: e.currentTarget.id.split('-')[0],
          kind: e.currentTarget.id.split('-')[2],
          user_id: e.currentTarget.dataset.userId,
          level: e.currentTarget.id.split('-')[1],
          remoteUrl: e.currentTarget.dataset.remoteUrl
        }
        let form_data = new FormData();

        for ( let key in access ) {
          form_data.append('access[' + key + ']', access[key]);
        }

        $(e.currentTarget).parents('div.modal').on('hide.bs.modal', function () {
          window.location.reload();
        })
        if (isAddAccess){
          $.ajax({
            type: "POST",
            url: access.remoteUrl,
            data: form_data,
            processData: false,
            contentType: false
          });
        } else {
          $.ajax({
            type: "DELETE",
            url: access.remoteUrl
          });
        }
      })

      $('.user-section-accesses-switcher').on('click', (e)=>{
        let isAddAccess = e.currentTarget.classList.contains('on')

        let access = {
          accessible_id: e.currentTarget.id.split('-')[0],
          kind: e.currentTarget.id.split('-')[2],
          user_id: e.currentTarget.dataset.userId,
          level: e.currentTarget.id.split('-')[1],
          remoteUrl: e.currentTarget.dataset.remoteUrl,
          accessible_type: 'Company'
        }
        let form_data = new FormData();

        for ( let key in access ) {
          form_data.append('access[' + key + ']', access[key]);
        }

        $(e.currentTarget).parents('div.modal').on('hide.bs.modal', function () {
          window.location.reload();
        })
        if (isAddAccess){
          $.ajax({
            type: "POST",
            url: access.remoteUrl,
            data: form_data,
            processData: false,
            contentType: false
          });
        } else {
          $.ajax({
            type: "DELETE",
            url: access.remoteUrl
          });
        }
      })
    });
  }
}

export default userNewAccess;
