import $ from 'jquery';

class cdpInsightProfilesWithMoreThanFivePrizes{
  constructor() {
    if ($('#cdp-insight-profiles-with-more-than-five-prizes').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-insight-profiles-with-more-than-five-prizes').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $('#cdp-insight-profiles-with-more-than-five-prizes .card-body .card-body h4').text(this.transform(response.profiles_count));
    $('#cdp-insight-profiles-with-more-than-five-prizes .card-body .card-text').html(`<b>${this.transform(response.profiles_count)}</b>, (<b>${this.transform(response.profiles_percentage)}%</b>) сегмента, участников имеют выигранные призы.`);
    $('#cdp-insight-profiles-with-more-than-five-prizes .peity-donut').text(`${response.profiles_percentage}/100`);
    $('#cdp-insight-profiles-with-more-than-five-prizes .peity-donut').peity('donut');
    this.loaded();
    this.toggleActionBtn(response.profiles_count);
  }

  toggleActionBtn(profiles_count){
    if (profiles_count > 0){
      $('#cdp-insight-profiles-with-more-than-five-prizes .segment-action-btn').removeClass('d-none');
    } else {
      $('#cdp-insight-profiles-with-more-than-five-prizes .segment-action-btn').addClass('d-none');
    }
  }

  loading(){
    $('#cdp-insight-profiles-with-more-than-five-prizes').addClass('loading');
  }

  loaded(){
    $('#cdp-insight-profiles-with-more-than-five-prizes').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpInsightProfilesWithMoreThanFivePrizes;