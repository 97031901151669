import $ from 'jquery';

class emailCampaignsSettings {
  constructor() {
    $('#campaign-started-at').datetimepicker({
      minDate:new Date(),
      format: 'DD.MM.YYYY HH:mm',
      locale: 'ru'
    });
    $('#campaign-started-at').on('keydown', (e)=>{
      e.preventDefault()
      return false
    })
  }

}

export default emailCampaignsSettings;