import $ from 'jquery';
import './jquery.peity.min'

class insightsDiagrams {
  constructor() {
    if($('.peity-donut').length){
      this.init()
    }
  }
  init() {
    $('.peity-donut').peity('donut');
  }


}

export default insightsDiagrams;