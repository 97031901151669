import $ from 'jquery';

class breadcrumbOverflow {
  constructor() {
    if ($('ol.breadcrumb').length) {
      this.init()
    }
  }

  init() {
    let sum = 0
    let maxWidth = 0
    let maxWidthElem
    setTimeout(()=>{
      $('ol.breadcrumb').children().each( (index, element) => {
        sum+=element.scrollWidth
        if(element.scrollWidth>maxWidth){
          maxWidthElem = element
        }
      });
      let diff = $('ol.breadcrumb').parent().width() - sum
      if(diff<0){
        sum = 0
        maxWidthElem.innerText = maxWidthElem.innerText.substr(0, 20) + '...'
        $('ol.breadcrumb').children().each( (index, element) => {
          sum+=element.scrollWidth
        });
        let diff = $('ol.breadcrumb').parent().width() - sum
        $('ol.breadcrumb').children().each( (index, element) => {
          let beginWidth = element.scrollWidth
          if(diff<0){
            element.innerText = '...'
            diff += beginWidth - element.scrollWidth
          }
        });
      }


    },100)

  }

}

export default breadcrumbOverflow;