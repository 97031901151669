import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, value } from '../options';

class RegistedCodesOnMemberWeekly {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-codes-on-member-weekly');
    let check = $('canvas').is('#charts__registed-codes-on-member-weekly');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_by_participant_weekly?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.average_actual.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let average_actual = {
          yAxisID: "lines",
          type: "line",
          label: "Количество",
          data: response.average_actual,
          backgroundColor: colors.blue[300],
          hoverBackgroundColor: colors.blue[500],
          borderColor: colors.blue[500],
          hoverBorderColor: colors.blue[500],
          hoverPointBackgroundColor: colors.red[500],
          pointBorderColor: colors.red[500],
          hoverPointBorderColor: colors.red[500],
          fill: false,
          datalabels: value
        }

        let average_plan = {
          yAxisID: "lines",
          type: "line",
          label: "План",
          data: response.average_plan,
          backgroundColor: colors.red[300],
          hoverBackgroundColor: colors.red[500],
          borderColor: colors.red[500],
          hoverBorderColor: colors.red[500],
          pointBackgroundColor: colors.red[300],
          hoverPointBackgroundColor: colors.red[500],
          pointBorderColor: colors.red[500],
          hoverPointBorderColor: colors.red[500],
          fill: false,
          datalabels: value
        }

        let datasets = []
        datasets.push(average_actual)

        if(response.average_plan.length > 0)
        {
          datasets.push(average_plan)
        }

        let data = {
          labels: response.labels,
          datasets: datasets
        };

        var numbers = response.average_plan.concat(response.average_actual);
        var maxValue = Math.max(...numbers);
        var maxValueOnAxis = ((20 / 100) * maxValue) + maxValue;
        var options = Object.assign({}, doubleAxes);

        options['scales']['yAxes'][1]['ticks']['max'] = Math.ceil(maxValueOnAxis);
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value)
        }

        let registedCodesOnMemberWeeklyChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });

        let showValue = true
        let newValueOptions = {}

        $('#registed-codes-on-member-weekly-switch').on('click', function(){
          $(this).toggleClass('on')

          if(showValue == true)
          {
            showValue = false
            newValueOptions = {}
          }
          else
          {
            showValue = true
            newValueOptions = value
          }

          data.datasets.forEach(function(dataset) {
            dataset['datalabels'] = newValueOptions
          })

          registedCodesOnMemberWeeklyChart.update()
        })
      }
    }
  }

  static onInit() {
    RegistedCodesOnMemberWeekly.Chart();
  }
}

export default RegistedCodesOnMemberWeekly;
