import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class UserRegistrationChannels2 {
  constructor() {
  }

  static Chart() {
    let ctx = $("#charts__user-registration-channels_2");
    let check = $("canvas").is("#charts__user-registration-channels_2");
    let project_id = $('[data-project-id]').data('project-id')

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/participants_signup_channels?project_id=' + project_id,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels_total,

          datasets: [
            {
              data: response.total,
              backgroundColor: [ colors.blue[300], colors.red[300] ],
              hoverBackgroundColor: [ colors.blue[500], colors.red[500] ],
              borderColor: [ colors.blue[500], colors.red[500] ],
              hoverBorderColor: [ colors.blue[500], colors.red[500] ],
              onlyPercentage: true,
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        var total_sum = response.total.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    UserRegistrationChannels2.Chart();
  }
}

export default UserRegistrationChannels2;
