import $ from 'jquery';

class cdpInsightWithoutCommunication{
  constructor() {
    if ($('#cdp-insight-without-communication').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-insight-without-communication').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    var promo_urls = []
    if (response.count > 0){
      response.names_and_uids.map(x => promo_urls.push(`<a href=${x.url} target="_blank">${x.name}</a>`))
      $('#cdp-insight-without-communication .card-body .card-body h4').text(this.transform(response.count));
      $('#cdp-insight-without-communication .card-body .card-text').html(`<b>${response.count}</b> участников завершающегося через <b>${this.transform(response.first_ended_promo)}</b> дней промо ${promo_urls.join(', ')} никогда не получали никакой коммуникации, не забудьте сделать рассылку о скором завершении промо.`);
    } else {
      $('#cdp-insight-without-communication .card-body .card-body h4').text(this.transform(response.count));
      $('#cdp-insight-without-communication .card-body .card-text').html(`Нет данных`);
    }

    $('#cdp-insight-without-communication .peity-donut').text(`${response.profiles_percentage}/100`);
    $('#cdp-insight-without-communication .peity-donut').peity('donut');
    this.loaded();
    this.toggleActionBtn(response.count);
  }

  toggleActionBtn(profiles_count){
    if (profiles_count > 0){
      $('#cdp-insight-without-communication .open-new-insight-segment-modal').removeClass('d-none');
    } else {
      $('#cdp-insight-without-communication .open-new-insight-segment-modal').addClass('d-none');
    }
  }

  loading(){
    $('#cdp-insight-without-communication').addClass('loading');
  }

  loaded(){
    $('#cdp-insight-without-communication').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpInsightWithoutCommunication;