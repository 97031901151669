window.Codes = {
  init: function(){
    $('.datatable').each(function(){
      var columns = $(this).find('thead th').map(function(){
        var data = { 'data': $(this).data('name'), 'orderable': $(this).data('orderable') }
        return $(this).data('name') !== undefined ? data : null;
      });

      $(this).DataTable({
        'searching': false,
        'paging': true,
        'info': false,
        'serverSide': true,
        'processing': true,
        'ordering': true,
        'order': [[0, 'desc']],
        'columns': columns,
        'ajax': {
          'url': $(this).data('url'),
          'dataSrc': 'data'
        }
      });
    })
  }
}
