export let colors = {
  transparent: "transparent",

  white: {
    primary: "#FFF",
    secondary: "rgba(255, 255, 255, .6)"
  },

  black: {
    primary: "#000",
    secondary: "rgba(0, 0, 0, .6)"
  },

  gray: {
    300: "#EEEEEE",
    500: "#BDBDBD"
  },

  red: {
    300: "#E57373",
    500: "#F44336"
  },

  purple: {
    300: "#CE93D8",
    500: "#AB47BC"
  },

  blue: {
    300: "#90CAF9",
    500: "#42A5F5"
  },

  green: {
    300: "#A5D6A7",
    500: "#66BB6A"
  },

  yellow: {
    300: "#FFF59D",
    500: "#FFEE58"
  },

  orange: {
    300: "#FFCC80",
    500: "#FFA726"
  },

  violet:{
    300: '#af69ee',
    500: '#b200ed'
  },

  anotherRed: {
    300: '#FF0000',
    500: '#880000'
  },
  orangeRed: {
    300: '#E55A2E',
    500: '#A5341E'
  },
  coral: {
    300: '#DC3D43',
    500: '#94292D'
  },
  crimson: {
    300: '#E91E63',
    500: '#880E4F'
  },
  pink: {
    300: '#E03177',
    500: '#AE1955'
  },
  plum: {
    300: '#D23197',
    500: '#7A1D5A'
  },
  magenta: {
    300: '#FF2080',
    500: '#C20054'
  },
  anotherViolet: {
    300: '#CF52E4',
    500: '#7F328C'
  },
  vividViolet: {
    300: '#A43CB4',
    500: '#8542658'
  },
  purpleHeart: {
    300: '#8445BC',
    500: '#5F2D84'
  },
  anotherPurple: {
    300: '#8A3FFC',
    500: '#652CBC'
  },
  royalPurple: {
    300: '#644FC1',
    500: '#443592'
  },
  orchid: {
    300: '#673AB7',
    500: '#311B92'
  },
  violetBlue: {
    300: '#3F51B5',
    500: '#1A237E'
  },
  indigo: {
    300: '#3A5CCC',
    500: '#273E89'
  },
  anotherBlue: {
    300: '#0F62FE',
    500: '#002D9C'
  },
  wave: {
    300: '#0091FF',
    500: '#006ADC'
  },
  cornflower: {
    300: '#33B1FF',
    500: '#00539A'
  },
  demin: {
    300: '#2196F3',
    500: '#0D47A1'
  },
  deepSkyBlue: {
    300: '#03A9F4',
    500: '#01579B'
  },
  cyan: {
    300: '#00BCD4',
    500: '#006064'
  },
  aquamarine: {
    300: '#3DB9CF',
    500: '#0894B3'
  },
  sky: {
    300: '#68DDFD',
    500: '#19B0DA'
  },
  mint: {
    300: '#3DDBD9',
    500: '#009D9A'
  },
  teal: {
    300: '#40C4AA',
    500: '#147D6F'
  },
  jungleGreen: {
    300: '#009688',
    500: '#004D40'
  },
  mountainMeadow: {
    300: '#12A594',
    500: '#067A6F'
  },
  fem: {
    300: '#299764',
    500: '#18794E'
  },
  viridian: {
    300: '#3AAF77',
    500: '#297C3B'
  },
  anotherGreen: {
    300: '#44CAF50',
    500: '#1B5E20'
  },
  greenLime: {
    300: '#42BE65',
    500: '#198038'
  },
  yellowGreen: {
    300: '#8BC34A',
    500: '#33691E'
  },
  lime: {
    300: '#CDDC39',
    500: '#827717'
  },
  electricLime: {
    300: '#99D52A',
    500: '#5D770D'
  },
  anotherYellow: {
    300: '#FFEB3B',
    500: '#E3CC00'
  },
  amber: {
    300: '#FFC107',
    500: '#D29E00'
  },
  gold: {
    300: '#EBBC00',
    500: '#946800'
  },
  carrot: {
    300: '#FFA01C',
    500: '#AD5700'
  },
  mango: {
    300: '#FF9800',
    500: '#E65100'
  },
  burntOrange: {
    300: '#FF5722',
    500: '#BF360C'
  },
  anotherOrange: {
    300: '#F76808',
    500: '#BD4B00'
  },
  peru: {
    300: '#AD7F58',
    500: '#886349'
  },
  olive: {
    300: '#978365',
    500: '#776750'
  },
  bronze: {
    300: '#A18072',
    500: '#846358'
  },
  slate: {
    300: '#8F8F8F',
    500: '#565656'
  },
  brown: {
    300: '#795548',
    500: '#3E2723'
  },
  sand: {
    300: '#9E9E9E',
    500: '#212121'
  },
  road: {
    300: '#607D8B',
    500: '#263238'
  },

  position: 0,
  list: [
    'red',
    'purple',
    'blue',
    'green',
    'yellow',
    'orange',
    'violet',
    'gray'
  ],
  getFilteredColor: function(preventedColors){
    let filteredList = this.list.filter(function(value){ return !preventedColors.includes(value) })
    return this[filteredList[this.position]]
  },
  getColor: function(){
    let current = this.list[this.position]
    return this[this.list[this.position]]
  },
  next: function(){
    this.position++
    if(this.position >= this.list.length)
      this.position = 0;
  },
  reset: function(){
    this.position = 0;
  }
};
