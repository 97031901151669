import $ from 'jquery';
import Glide from "@glidejs/glide";
import WZoom from "vanilla-js-wheel-zoom";

class solutions {
  constructor() {
    if ($('#solutions-body').length) {
      this.init()
    }
  }


  glide


  init() {
    $('.az-invoice-list .media').on('click touch', function() {
      $(this).addClass('selected').removeClass('new');
      $(this).siblings().removeClass('selected');

      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').addClass('az-content-body-show');
        $('html body').scrollTop($('html body').prop('scrollHeight'));
      }
    });
    if(window.matchMedia('(min-width: 991px)').matches){
      $('[data-toggle="tooltip"]').tooltip();
    }


    $('#azChatBodyHide').on('click touch', function(e){
      e.preventDefault();
      $('body').removeClass('az-content-body-show');
    })




    $(document).on('mouseenter touchstart click', '.receipts-glider', () => {
      if (!$('.receipts-glider').hasClass('bound')) {
        this.glide = new Glide('.receipts-glider ', {
          dragThreshold: false,
          keyboard: false,
          swipeThreshold: false,
        })

        let thisGlide = this.glide
        document.querySelector('.glide__arrow--right').addEventListener('click', function (event) {
          event.preventDefault();

          thisGlide.go('>');
        })

        document.querySelector('.glide__arrow--left').addEventListener('click', function (event) {
          event.preventDefault();

          thisGlide.go('<');
        })

        document.querySelectorAll('.receipt-image').forEach((el)=>{
          WZoom.create(el, {
            minScale: 1,
            maxScale: 5,
            zoomOnClick: false,
            speed: ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0) ? 3 : 1,
          });
        })


        this.glide.mount()
        $('.receipts-glider').addClass('bound')
      }
    })

  }

}

export default solutions;