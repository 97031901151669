import $ from 'jquery';
import { colors } from '../colors';

class GraphicProjectTests {
  constructor() {
  }

  static Chart() {
    let charts = $('.charts__graphic-project-test');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');

    if(charts.length > 0) {
      let getChartData = function(chart, projectTestId){
        $.ajax({
          url: '/api/v1/statistics/monitoring_data?project_test_id=' + projectTestId + '&start_week=' + first_week + '&end_week=' + end_week,
          method: 'GET',
          dataType: 'json',
          success: function (response) {
            initChart(chart, response);
          }
        });
      };

      $.each(charts, function(){
        getChartData($(this), $(this).data('id'))
      });

      let initChart = function(chart, response){
        let data = {
          labels: response.labels,
          datasets: []
        };

        colors.reset()
        for(let benchmark in response.benchmarks){
          let currentColor = colors.getFilteredColor(['red', 'orange', 'green'])

          data.datasets.push({
            label: response.labels[benchmark],
            data: response.benchmarks[benchmark],
            colors: response.colors[benchmark],
            backgroundColor: currentColor[300],
            hoverBackgroundColor: currentColor[500],
            borderColor: currentColor[500],
            hoverBorderColor: currentColor[500],
            hoverPointBackgroundColor: currentColor[500],
            pointBorderColor: currentColor[500],
            hoverPointBorderColor: currentColor[500],
            fill: false
          })

          colors.next()
        }

        let ctx = chart[0].getContext('2d');

        new Chart(ctx, {
          type: 'multicolorLine',
          labels: response.labels,
          data: {
            datasets: data.datasets
          },
          options: {
            responsive: true,

            legend: {
              display: true,

              labels: {
                fontColor: colors.black.primary,
                fontFamily: "Roboto"
              }
            },

            tooltips: {
              mode: "index",
              intersect: true
            },

            scales: {
              xAxes: [{
                type: 'time',
                time: {
                  unit: 'day',
                  unitStepSize: 1,
                  displayFormats: {
                     'day': 'MMM DD'
                  },
                  tooltipFormat:'DD.MM.YYYY HH:mm:ss'
                }
              }]
            },

            plugins: {
              datalabels: {
                color: colors.transparent
              }
            }
          }
        });
      }
    }
  }

  static onInit() {
    GraphicProjectTests.Chart();
  }
}

export default GraphicProjectTests;
