import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, value, percentage } from '../options';

class RegistedReceiptsPeriod {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-receipts-period');
    let check = $('canvas').is('#charts__registed-receipts-period');
    let project_id = $('[data-project-id]').data('project-id');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/receipts_by_periods?project_id=' + project_id + '&start_week=' + first_week + '&end_week=' + end_week,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels_last_week,

          datasets: [
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество валидных чеков (план за период)",
              data: response.receipts_plan_last_week,
              backgroundColor: colors.green[300],
              hoverBackgroundColor: colors.green[500],
              borderColor: colors.green[500],
              hoverBorderColor: colors.green[500],
              fill: false,
              datalabels: value
            },
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество валидных чеков (факт за период)",
              data: response.valid_receipts_actual_last_week,
              backgroundColor: colors.yellow[300],
              hoverBackgroundColor: colors.yellow[500],
              borderColor: colors.yellow[500],
              hoverBorderColor: colors.yellow[500],
              fill: false,
              datalabels: value
            },
            {
              type: "scatter",
              label: "Процент от плана",
              data: response.plan_percentage_last_week,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              showLine: false,
              fill: false,
              datalabels: percentage
            }
          ]
        };

        var options = Object.assign({}, doubleAxes);
        options['scales']['yAxes'][1]['ticks']['max'] = 100;
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value) + '%';
        };

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    RegistedReceiptsPeriod.Chart();
  }
}

export default RegistedReceiptsPeriod;
