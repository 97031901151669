import $ from 'jquery';

class projectReceipts {
  constructor() {
    if ($('#project-receipts').length || $('#project-receipts-list').length) {
      this.init()
    }
  }

  init() {
    $('#project-receipts_nav').lightSlider({
      autoWidth: true,
      controls: false,
      pager: false,
      slideMargin: 12
    });


    $('.az-chat-list .media').on('click touch', function() {
      $('.az-chat-list .media').removeClass('selected');
      $(this).addClass('selected');

      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').addClass('az-content-body-show');
        $('html body').scrollTop($('html body').prop('scrollHeight'));
      }
    });

    $('#receiptsFiltersCloseArrow').on('click', function (e){
      e.preventDefault();
      $('#allReceiptsFilterContainer').collapse('toggle').removeClass('dropdown-menu')
      $('.az-header').removeClass('d-none')
    })

    $('#azChatBodyHide').on('click touch', function(e){
      e.preventDefault();
      $('body').removeClass('az-content-body-show');
    })

    if(window.matchMedia('(max-width: 991px)').matches) {
      $('#created_at_filter_min-datestamp').attr('type', 'datetime-local').removeAttr('readonly')
      $('.input-group-append[data-target="#created_at_filter_min-datestamp"]').addClass('d-none')

      $('#created_at_filter_max-datestamp').attr('type', 'datetime-local').removeAttr('readonly')
      $('.input-group-append[data-target="#created_at_filter_max-datestamp"]').addClass('d-none')
    }

  }

}

export default projectReceipts;