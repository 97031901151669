window.Calendar = {
  init: function(){
    if ($('#calendar').length == 0)
      return;

    moment.locale('ru');

    $('#dateToday').text(moment().format('ddd, MMMM DD YYYY'));

    var curYear = moment().format('YYYY');
    var curMonth = moment().format('MM');

    var date = new Date();

    var eventsSourceURL = function() {
      var baseURL, params;
      baseURL = $('#calendar').data('events-source');
      params = $('#calendar-filters').serialize();
      console.log(baseURL)
      console.log(params)
      if (params.length > 0) {
        return baseURL + "?" + params;
      } else {
        return baseURL;
      }
    };

    $('.fc-datepicker').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      dateFormat: 'yy-mm-dd'
    });

    // Initialize fullCalendar
    $('#calendar').fullCalendar({
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,agendaWeek,agendaDay,listWeek'
      },
      buttonText: {
        today: 'Сегодня',
        month: 'Месяц',
        week: 'Неделя',
        day: 'День',
        list: 'Список'
      },
      timeFormat: 'H(:mm)',
      locale: 'ru',
      navLinks: true,
      selectable: true,
      selectLongPressDelay: 100,
      editable: true,
      nowIndicator: true,
      defaultView: 'listMonth',
      views: {
        agenda: {
          columnHeaderHtml: function(mom) {
            return '<span>' + mom.format('ddd') + '</span>' +
                   '<span>' + mom.format('DD') + '</span>';
          }
        },
        day: { columnHeader: false },
        listMonth: {
          listDayFormat: 'ddd DD',
          listDayAltFormat: false
        },
        listWeek: {
          listDayFormat: 'ddd DD',
          listDayAltFormat: false
        },
        agendaThreeDay: {
          type: 'agenda',
          duration: { days: 3 },
          titleFormat: 'MMMM YYYY'
        }
      },
      events: eventsSourceURL(),
      eventAfterAllRender: function(view) {
        if(view.name === 'listMonth' || view.name === 'listWeek') {
          var dates = view.el.find('.fc-list-heading-main');
          dates.each(function(){
            var text = $(this).text().split(' ');
            var now = moment().format('DD');

            $(this).html(text[0]+'<span>'+text[1]+'</span>');
            if(now === text[1]) { $(this).addClass('now'); }
          });
        }

        console.log(view.el);
      },
      eventRender: function(event, element) {

        if(event.description) {
          element.find('.fc-list-item-title').append('<span class="fc-desc">' + event.description + '</span>');
          element.find('.fc-content').append('<span class="fc-desc">' + event.description + '</span>');
        }

        var eBorderColor = (event.source.borderColor)? event.source.borderColor : event.borderColor;
        element.find('.fc-list-item-time').css({
          color: eBorderColor,
          borderColor: eBorderColor
        });

        element.find('.fc-list-item-title').css({
          borderColor: eBorderColor
        });

        element.css('borderLeftColor', eBorderColor);
      },
    });

    var eventsCalendar = $('#calendar').fullCalendar('getCalendar');

    // change view to week when in tablet
    if(window.matchMedia('(min-width: 576px)').matches) {
      eventsCalendar.changeView('agendaWeek');
    }

    // change view to month when in desktop
    if(window.matchMedia('(min-width: 992px)').matches) {
      eventsCalendar.changeView('month');
    }

    // change view based in viewport width when resize is detected
    eventsCalendar.option('windowResize', function(view) {
      if(view.name === 'listWeek') {
        if(window.matchMedia('(min-width: 992px)').matches) {
          eventsCalendar.changeView('month');
        } else {
          eventsCalendar.changeView('listWeek');
        }
      }
    });

    // display current date
    var azDateNow = eventsCalendar.getDate();
    eventsCalendar.option('select', function(startDate, endDate){
      if (endDate < moment()) {
        alert('Нельзя создавать события в прошлом');
      } else {
        $('#new-event-modal').modal('show');
        $('#event_start_at').val(startDate.format('YYYY-MM-DD') + ' ' + moment().format('HH:MM'));
        $('#event_end_at').val(startDate.format('YYYY-MM-DD') + ' ' + moment().add(1, 'hours').format('HH:MM'));
      }
    });

    // Display calendar event modal
    eventsCalendar.on('eventClick', function(calEvent, jsEvent, view){

      var modal = $('#show-event-modal');

      modal.modal('show');
      modal.find('.event-title').text(calEvent.title);

      if(calEvent.description) {
        modal.find('.event-desc').text(calEvent.description);
        modal.find('.event-desc').prev().removeClass('d-none');
      } else {
        modal.find('.event-desc').text('');
        modal.find('.event-desc').prev().addClass('d-none');
      }

      modal.find('.event-start-date').text(moment(calEvent.start).format('LLL'));
      modal.find('.event-end-date').text(moment(calEvent.end).format('LLL'));

      if (calEvent.doneUrl){
        modal.find('.btn-success').show();
        modal.find('.btn-success').attr('href', calEvent.doneUrl);
      }else{
        modal.find('.btn-success').hide();
      }

      if (calEvent.destroyUrl){
        modal.find('.destroy-event-link').show();
        modal.find('.destroy-event-link').attr('href', calEvent.destroyUrl);
      }else{
        modal.find('.destroy-event-link').hide();
      }

      if (calEvent.doneAt){
        modal.find('.done-date-container').show();
        modal.find('.event-done-date').text(moment(calEvent.doneAt).format('LLL'));
      }else{
        modal.find('.done-date-container').hide();
      }

      //styling
      modal.find('.modal-header').css('backgroundColor', (calEvent.source.borderColor)? calEvent.source.borderColor : calEvent.borderColor);
    });

    // Enable/disable calendar events from displaying in calendar
    $('.az-nav-calendar-event a').on('click', function(e){
      e.preventDefault();
      if($(this).hasClass('exclude')) {
        $(this).removeClass('exclude');

        $(this).find('input').prop( "checked", true ).change();
      } else {
        $(this).addClass('exclude');

        $(this).find('input').prop( "checked", false ).change();
      }

      if(window.matchMedia('(max-width: 575px)').matches) {
        $('body').removeClass('az-content-left-show');
      }
    });

    $('#calendar-filters input').change(function(e){
      console.log('changed')
      eventsCalendar.removeEventSource(eventsSourceURL());
      eventsCalendar.removeEvents();
      eventsCalendar.addEventSource(eventsSourceURL());
    });
  },
  refetchEvents: function(){
    $('#calendar').fullCalendar( 'refetchEvents' );
  }
}
