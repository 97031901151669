import consumer from "./consumer"

window.supportTicketSubscription = function(){
  consumer.subscriptions.create({ channel: "SupportTicketChannel" }, {
    connected() {
      console.log("Connected to the channel:", this);
    },

    disconnected() {
      console.log("Disconnected");
    },

    received: function(data) {
      console.log("Received some data:", data);
      $.ajax({
        url: '/user_tickets?opened_ticket_id=' + $('#chat__section #azChatBody').data('chat_id'),
        method: 'GET',
        dataType: 'script'
      }).success(function(){
        $('#chat__section #azChatBody').scrollTop($('#chat__section #azChatBody').prop('scrollHeight'));
      })
    }
  });
}

$(document).ready( function () {
  supportTicketSubscription()
})