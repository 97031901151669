import $ from 'jquery';

class Quests {
  constructor() {
    if ($('#quests_table').length > 0) {
      this.init()
    }
  }

  init() {
    this.init_table()
  }

  init_table() {
    var columns = [
      { 'data': 'id' },
      { 'data': 'name' },
      { 'data': 'description' },
      { 'data': 'started_at' },
      { 'data': 'finished_at' },
      { 'data': 'reward' },
      { 'data': 'wallet_slug' },
    ]

    if ($('#quests_table').data('role') == 'editor')
      columns.push({
        'data': 'actions',
        orderable: false
      })

    this.table = $('#quests_table').DataTable({
      'searching': false,
      'paging': true,
      'info': false,
      'serverSide': true,
      'processing': true,
      'ordering': true,
      'order': [
        [0, 'desc']
      ],
      // # NOTE: Change also set of columns in controller
      'columns': columns,
      'ajax': {
        'url': $('#quests_table').data('url'),
        'dataSrc': 'data'
      }
    });
  }

  update_table() {
    this.table.draw(false);
  }
}

export default Quests;