import $ from 'jquery';

import cdpInsightProfilesWithPrizes from './insights/profiles_with_prizes';
import cdpInsightProfilesWithMoreThanFivePrizes from './insights/profiles_with_more_than_five_prizes';
import cdpInsightParticipantsWithSingleReceipt from './insights/participants_with_single_receipt';
import cdpInsightProfilesWithPrizehunter from './insights/profiles_with_prizehunter';
import cdpInsightUnconfirmedEmails from './insights/unconfirmed_emails';
import cdpInsightPotentialReceipts from './insights/potential_participants';
import cdpInsightProfilesWithBirthdayInNearestWeek from './insights/profiles_with_birthday_in_nearest_week';
import cdpInsightProfilesWithUnansweredTickets from './insights/profiles_with_unanswered_tickets'
import cdpInsightMostPopularContragents from './insights/most_popular_contragents'
import cdpInsightMostPopularRetailPlace from './insights/most_popular_retail_place'
import cdpInsightWithoutCommunicationAggreement from './insights/without_communication_aggreement'
import cdpInsightHackers from './insights/hackers'
import cdpInsightWithoutCommunicationsInTwoWeeks from './insights/without_communications_in_two_weeks'
import cdpInsightWithoutCommunication from './insights/without_communication'

class cdpInsights{
  constructor() {
    if ($('#cdp-profiles-insights').length > 0){
      this.init()
    }
  }

  init(){
    new cdpInsightProfilesWithPrizes()
    new cdpInsightProfilesWithMoreThanFivePrizes()
    new cdpInsightParticipantsWithSingleReceipt()
    new cdpInsightProfilesWithPrizehunter()
    new cdpInsightUnconfirmedEmails()
    new cdpInsightPotentialReceipts()
    new cdpInsightProfilesWithBirthdayInNearestWeek()
    new cdpInsightProfilesWithUnansweredTickets()
    new cdpInsightMostPopularRetailPlace()
    new cdpInsightMostPopularContragents()
    new cdpInsightWithoutCommunicationAggreement()
    new cdpInsightHackers()
    new cdpInsightWithoutCommunicationsInTwoWeeks()
    new cdpInsightWithoutCommunication()
  }
}

export default cdpInsights;
