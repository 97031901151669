window.Products = {
  init: function () {
    $(document).ready(function () {
      $('.select2-find_product').select2({
        language: {
          noResults: function () {
            return 'Продукт не найден';
          }
        },
        placeholder: 'Выберите продукт',
        searchInputPlaceholder: 'Поиск',
        width: '100%',
        ajax: {
          url: '/api/v1/products.json',
          data: function (params) {
            query = {
              q: params.term,
              project_id: $('#find-products-by-project-id').val()
            }

            return query;
          },
          dataType: 'json',
          processResults: function (data, page) {
            return {
              results: JQuery.map(data, function (city, i) {
                return {
                  id: city.id,
                  text: city.name
                }
              })
            };
          }
        }
      }).on('select2:select', function(e){
        var data = e.params.data;
        $('.hidden.add-alias').removeClass('hidden')
      });
    });
  }
}
