import consumer from "./consumer"

$(document).ready(function () {
  $.each($('.report-progress-bar'), function () {
    consumer.subscriptions.create({ channel: "ReportChannel", id: $(this).data('id') }, {
      connected() {
        console.log("Connected to the channel:", this);
      },

      disconnected() {
        console.log("Disconnected");
      },

      received(data) {
        console.log("Received some data:", data);
        let at = data.at;
        let id = data.id;
        if (at == 100) {
          $(`.progress-field-${id}`).addClass('d-none');
          $(`.download-field-${id}`).removeClass('d-none');
        } else {
          $(`.progress-field-${id}`).removeClass('d-none');
          $(`.progress-bar-${id}`).addClass(`wd-${at}p`);
          $(`.progress-field-${id} .progress-value`).text(`${at}%`);
        }
      }
    });
  });
});
