import $ from 'jquery';

class WeekTraffic{
  constructor() {
    if ($('#week_traffic').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        $.ajax({
          url: $('#week_traffic').data('url'),
          method: 'GET',
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    var difference = response.difference.toString()
    if (difference > 0) { difference = '+' + difference }
    $(`#week_traffic #count`).text(`${response.total_count}`)
    $(`#week_traffic #difference`).html(`${difference}% с прошлой недели`)

    var ctx = document.getElementById('cdp-profile_chartArea').getContext('2d');

    var gradient = ctx.createLinearGradient(0, 240, 0, 0);
    gradient.addColorStop(0, 'rgba(0,123,255,0)');
    gradient.addColorStop(1, 'rgba(0,123,255,.3)');

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: response.dates,
        datasets: [{
          data: response.counts,
          borderColor: '#007bff',
          borderWidth: 1,
          backgroundColor: gradient
        }]
      },
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false,
          labels: {
            display: false
          }
        },
        scales: {
          yAxes: [{
            display: false,
            ticks: {
              beginAtZero:true,
              fontSize: 10,
              max: response.max * 2,
              min: 0
            }
          }],
          xAxes: [{
            ticks: {
              beginAtZero:true,
              fontSize: 11,
              fontFamily: 'Arial'
            }
          }]
        }
      }
    });
    
    this.loaded();
  }

  loading(){
    $('#week_traffic').addClass('loading');
  }

  loaded(){
    $('#week_traffic').removeClass('loading');
  }
}

export default WeekTraffic;