import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, percentage_line } from '../options';

class RegistedCodesOnMember {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-codes-on-member');
    let check = $('canvas').is('#charts__registed-codes-on-member');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_by_participants_count?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels,

          datasets: [
            {
              yAxisID: "lines",
              type: "line",
              label: "Процент",
              data: response.percentages,
              backgroundColor: colors.red[300],
              hoverBackgroundColor: colors.red[500],
              borderColor: colors.red[500],
              hoverBorderColor: colors.red[500],
              pointBackgroundColor: colors.red[300],
              hoverPointBackgroundColor: colors.red[500],
              pointBorderColor: colors.red[500],
              hoverPointBorderColor: colors.red[500],
              fill: false,
              showLine: false,
              datalabels: percentage_line
            },
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество валидных кодов",
              data: response.counts,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500],
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        var options = Object.assign({}, doubleAxes);

        var maxTicks = Math.max(...response.percentages) / 100
        maxTicks = Math.ceil(maxTicks) * 100;
        options['scales']['yAxes'][1]['ticks']['max'] = maxTicks
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value) + '%'
        }

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    RegistedCodesOnMember.Chart();
  }
}

export default RegistedCodesOnMember;
