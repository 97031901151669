import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class ReceiptsBySku {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__receipts_by_sku');
    let check = $('canvas').is('#charts__receipts_by_sku');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/receipts_by_sku?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        //if (response.data[0] == 0 && response.data[1] == 0){
        //  $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
        //  return;
        //}

        let ligherColors = [
          colors.red[300], colors.magenta[300], colors.anotherViolet[300],
          colors.vividViolet[300], colors.purpleHeart[300], colors.anotherPurple[300],
          colors.royalPurple[300], colors.orchid[300], colors.violetBlue[300],
          colors.indigo[300], colors.anotherBlue[300], colors.wave[300], colors.cornflower[300],
          colors.demin[300], colors.deepSkyBlue[300], colors.cyan[300], colors.aquamarine[300],
          colors.sky[300], colors.mint[300], colors.teal[300], colors.jungleGreen[300],
          colors.mountainMeadow[300], colors.fem[300], colors.viridian[300], colors.anotherGreen[300],
          colors.greenLime[300], colors.yellowGreen[300], colors.lime[300], colors.electricLime[300],
          colors.anotherYellow[300], colors.purple[300], colors.blue[300], colors.green[300],
          colors.yellow[300], colors.orange[300], colors.violet[300], colors.anotherRed[300],
          colors.orangeRed[300], colors.coral[300], colors.crimson[300], colors.pink[300],
          colors.plum[300], colors.amber[300], colors.gold[300], colors.carrot[300],
          colors.mango[300], colors.burntOrange[300], colors.anotherOrange[300],
          colors.peru[300], colors.olive[300], colors.bronze[300], colors.slate[300],
          colors.brown[300], colors.sand[300], colors.road[300]
        ]

        let darkerColors = [
          colors.red[500], colors.magenta[500], colors.anotherViolet[500],
          colors.vividViolet[500], colors.purpleHeart[500], colors.anotherPurple[500],
          colors.royalPurple[500], colors.orchid[500], colors.violetBlue[500],
          colors.indigo[500], colors.anotherBlue[500], colors.wave[500], colors.cornflower[500],
          colors.demin[500], colors.deepSkyBlue[500], colors.cyan[500], colors.aquamarine[500],
          colors.sky[500], colors.mint[500], colors.teal[500], colors.jungleGreen[500],
          colors.mountainMeadow[500], colors.fem[500], colors.viridian[500], colors.anotherGreen[500],
          colors.greenLime[500], colors.yellowGreen[500], colors.lime[500], colors.electricLime[500],
          colors.anotherYellow[500], colors.purple[500], colors.blue[500], colors.green[500],
          colors.yellow[500], colors.orange[500], colors.violet[500], colors.anotherRed[500],
          colors.orangeRed[500], colors.coral[500], colors.crimson[500], colors.pink[500],
          colors.plum[500], colors.amber[500], colors.gold[500], colors.carrot[500],
          colors.mango[500], colors.burntOrange[500], colors.anotherOrange[500],
          colors.peru[500], colors.olive[500], colors.bronze[500], colors.slate[500],
          colors.brown[500], colors.sand[500], colors.road[500]
        ]

        let data = {
          labels: response.labels,

          datasets: [
            {
              data: response.data,
              backgroundColor: ligherColors,
              hoverBackgroundColor: darkerColors,
              borderColor: darkerColors,
              hoverBorderColor: darkerColors,
              fill: true,
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        };

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});
        options['legend']['display'] = false

        options['legendCallback'] =   function(chart) {
          let currentDataset = chart.data.datasets[0];
          let dataSum = currentDataset.data.reduce((a, b) => a + b, 0);

          for (let i = 0; i < currentDataset.data.length; i++) {
            let iconColor = currentDataset.backgroundColor[i];

            let value = currentDataset.data[i];
            let valueString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            let percentage = (value * 100 / dataSum).toFixed(2);

            let media = $('<div class="col-sm-12 js-receipts-by-sku mg-t-20"><div>')
            let media_body = $('<div class="circle-media js-valid-stats"></div>')
            let icon = $(`<div class="circle-media-icon" style="color: ${iconColor}"><i class="icon fas fa-receipt"></i></div>`)
            let numbers = $('<div class="circle-media-body"></div>')
            let h6 = $('<h6></h6>')
            let count_div = $(`<span class="count">${valueString}</span>`)
            let percentage_div = $(`<span class="percentage"> (${percentage}%)</span>`)
            let product_name = $(`<span>${response.labels[i]}</span>`)

            h6.append(count_div, percentage_div)
            numbers.append(h6)
            media_body.append(icon, numbers)
            media.append(media_body, product_name)

            $('#receipts-by-sku-legend').append(media)
          }
        }

        let incorrectReceiptsChart1 = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
        incorrectReceiptsChart1.generateLegend();
      }
    }
  }

  static onInit() {
    ReceiptsBySku.Chart();
  }
}

export default ReceiptsBySku;
