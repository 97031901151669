import $ from 'jquery';

class MainAnalyticsConversionFunnel{
  constructor() {
  }

  static Data(){
    if ($('#conversion-funnel').length > 0){
      let project_id = $('[data-project-id]').data('project-id')
      let env = $('[data-env]').data('env')

      let first_week = parseUrl('start_week')
      let end_week = parseUrl('end_week')
      let days_from = parseUrl('days_from')
      let days_to = parseUrl('days_to')
      let period_filter_type = parseUrl('period_filter_type')
      let urlParams = 'project_id=' + project_id +
                      '&start_week=' + first_week +
                      '&end_week=' + end_week +
                      '&days_from=' + days_from +
                      '&days_to=' + days_to +
                      '&env=' + env +
                      '&period_filter_type=' + period_filter_type

      $(document).ready(function() {
        setTimeout(function() {
          $.ajax({
            url: '/api/v1/statistics/main_analytics_conversion_funnel?' + urlParams,
            method: 'GET',
            success: function (response) {
              MainAnalyticsConversionFunnel.renderStats(response)
            }
          });
        }, 1000);
      })
    }
  }

  static renderStats(response){
    $('#conversion-funnel #visitors_count').text(`${MainAnalyticsConversionFunnel.transform(response.visitors_count)}`);
    $('#conversion-funnel #registrations_count').text(`${MainAnalyticsConversionFunnel.transform(response.registrations_count)}`);
    $('#conversion-funnel #participants_with_receipts').text(`${MainAnalyticsConversionFunnel.transform(response.participants_with_receipts)}`);
    $('#conversion-funnel #receipts_count').text(`${MainAnalyticsConversionFunnel.transform(response.receipts_count)}`);
    $('#conversion-funnel #valid_receipts_count').text(`${MainAnalyticsConversionFunnel.transform(response.valid_receipts_count)}`);
    $('#conversion-funnel #everyday_prizes_count').text(`${MainAnalyticsConversionFunnel.transform(response.everyday_prizes_count)}`);

    $('#conversion-funnel #kpi').html(`<strong>${MainAnalyticsConversionFunnel.transform(response.kpi)}%</strong> из ${MainAnalyticsConversionFunnel.transform(response.plan_total_period)}`);
    $('#conversion-funnel #percentage_of_completion').html(`<strong>${MainAnalyticsConversionFunnel.transform(response.percentage_of_completion)}%</strong> из ${MainAnalyticsConversionFunnel.transform(response.plan_total)}`);
    $('#conversion-funnel #current_for_period').text(`${MainAnalyticsConversionFunnel.transform(response.current_for_period)}`);
    $('#conversion-funnel #current_for_period_completion').text(`${MainAnalyticsConversionFunnel.transform(response.current_for_period)}`);

    if (response.plans_exists) {
      $('#conversion-funnel #plans').removeClass('d-none');
    }
  }

  static transform(number){
    return parseFloat(number).toLocaleString('ru')
  }

  static onInit(){
    MainAnalyticsConversionFunnel.Data()
  }
}

export default MainAnalyticsConversionFunnel;