import $ from 'jquery';
import { colors } from '../colors';
import { horizontalLabels, singleAxes, doubleAxes, percentage_line } from '../options';

class PrizesBaltikaZg {
  constructor() {
  }

  static Chart(callback) {
    let ctx = $('#charts__prizes-baltika-zg')
    let check = $('canvas').is('#charts__prizes-baltika-zg')
    let project_id = $('[data-project-id]').data('project-id')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/prizes_baltika_zg?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let polo_total = response.polo_magnit_count + response.polo_carousel_count + response.polo_verniy_count + response.polo_others_count + response.polo_dixy_count
        let cup_total = response.cup_magnit_count + response.cup_carousel_count + response.cup_verniy_count + response.cup_others_count + response.cup_dixy_count
        new Chart(ctx, {
          type: "horizontalBar",
          data: {
            labels: ['Total', 'Другие сети', 'Дикси', 'Верный', 'Карусель', 'Магнит'],
            datasets: [
              {
                label: 'Футболка/Поло - План',
                data: [3570, 900, 600, 270, 300, 1500],
                backgroundColor: colors.green[300],
                hoverBackgroundColor: colors.green[500],
                borderColor: colors.green[500],
                hoverBorderColor: colors.green[500]
              },
              {
                label: 'Футболка/Поло - Факт',
                data: [polo_total, response.polo_others_count, response.polo_dixy_count, response.polo_verniy_count, response.polo_carousel_count, response.polo_magnit_count],
                backgroundColor: colors.yellow[300],
                hoverBackgroundColor: colors.yellow[500],
                borderColor: colors.yellow[500],
                hoverBorderColor: colors.yellow[500]
              },
              {
                label: 'Бокал - План',
                data: [5808, 1350, 1008, 360, 390, 2700],
                backgroundColor: colors.blue[300],
                hoverBackgroundColor: colors.blue[500],
                borderColor: colors.blue[500],
                hoverBorderColor: colors.blue[500]
              },
              {
                label: 'Бокал - Факт',
                data: [cup_total, response.cup_others_count, response.cup_dixy_count, response.cup_verniy_count, response.cup_carousel_count, response.cup_magnit_count],
                backgroundColor: colors.orange[300],
                hoverBackgroundColor: colors.orange[500],
                borderColor: colors.orange[500],
                hoverBorderColor: colors.orange[500]
              }
            ]
          },
          options: horizontalLabels
        });
      }
    }
  }

  static onInit() {
    PrizesBaltikaZg.Chart();
  }
}

export default PrizesBaltikaZg;
