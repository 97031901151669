import $ from 'jquery';

class externalTemplatesModals {
  constructor() {
    $('.external-template-preview-toggle-button').click(function () {
        console.log($(this).data('img-src'))
        $('#external-template-preview-modal').modal('show')

        $('#external-template-preview-modal-img').attr('src', $(this).data('img-src'))
    })

  }
}
export default externalTemplatesModals;