import $ from 'jquery';
import { colors } from '../colors';
import { horizontalLabels, singleAxes, doubleAxes, percentage_line } from '../options';

class PrizesSelected2 {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__prizes-selected_2')
    let check = $('canvas').is('#charts__prizes-selected_2')
    let project_id = $('[data-project-id]').data('project-id')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/prizes_total?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.labels == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }
        if (response.labels.length <= 20){
          let actual_dataset = {
            yAxisID: "bars",
            type: "bar",
            label: "Факт",
            stack: "Stack 0",
            data: response.actual,
            backgroundColor: colors.red[300],
            hoverBackgroundColor: colors.red[500],
            borderColor: colors.red[500],
            hoverBorderColor: colors.red[500],
            datalabels: {
              color: colors.white.primary
            }
          }

          datasets.push(actual_dataset)

          if (response.plan){
            let plan_datasets = [
              {
                yAxisID: "lines",
                type: "line",
                label: "Процент",
                data: response.percentage,
                backgroundColor: colors.orange[300],
                hoverBackgroundColor: colors.orange[500],
                borderColor: colors.orange[500],
                hoverBorderColor: colors.orange[500],
                pointBackgroundColor: colors.orange[300],
                hoverPointBackgroundColor: colors.orange[500],
                pointBorderColor: colors.orange[500],
                hoverPointBorderColor: colors.orange[500],
                fill: false,
                showLine: false,
                datalabels: percentage_line
              },
              {
                yAxisID: "bars",
                type: "bar",
                label: "План",
                stack: "Stack 1",
                data: response.plan,
                backgroundColor: colors.blue[300],
                hoverBackgroundColor: colors.blue[500],
                borderColor: colors.blue[500],
                hoverBorderColor: colors.blue[500],
                datalabels: {
                  color: colors.white.primary
                }
              }
            ]
            $.merge(datasets, plan_datasets)
            options = doubleAxes
          }

          let data = {
            labels: response.labels,
            datasets: datasets
          }

          new Chart(ctx, {
            type: "bar",
            data: {
              labels: data.labels,
              datasets: data.datasets
            },
            options: options
          });
        }else{
          new Chart(ctx, {
            type: "horizontalBar",
            data: {
              labels: response.labels,
              datasets: [{
                label: 'Количество',
                data: response.actual,
                backgroundColor: colors.red[300],
                hoverBackgroundColor: colors.red[500],
                borderColor: colors.red[500],
                hoverBorderColor: colors.red[500]
              }]
            },
            options: Object.assign({ responsive: true, maintainAspectRatio: false }, horizontalLabels)
          });
        }
      }
    }
  }

  static onInit() {
    PrizesSelected2.Chart();
  }
}

export default PrizesSelected2;
