import $ from 'jquery';
import { colors } from '../../charts/colors';
import { pie } from '../../charts/options';

class ChartGendersCount {
  constructor() {
    let elementExists = $(this.elementId()).length > 0
    let isCanvas = $('canvas').is(this.elementId());

    if (elementExists && isCanvas){
      this.loading()
      this.init()
    }
  }

  init() {
    var thisObject = this;

    $(document).ready(function() {
      setTimeout(function() {
        $.ajax({
          url: $(thisObject.elementId()).data('url'),
          method: 'GET',
          dataType: 'json',
          data: { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) },
          success: function (response) {
            thisObject.initChart(response)
            thisObject.loaded(response)
          }
        });
      }, 1000);
    })
  }

  initChart(response){
    //if (response.data[0] == 0 && response.data[1] == 0){
    //  $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
    //  return;
    //}

    let ctx = $(this.elementId());
    let data = {
      labels: response.labels,
      datasets: [
        {
          data: response.data,
          backgroundColor: ['#3366ff', '#f10075', '#b4bdce'],
          hoverBackgroundColor: ['#3366ff', '#f10075', '#b4bdce'],
          borderColor: ['#3366ff', '#f10075', '#b4bdce'],
          hoverBorderColor: ['#3366ff', '#f10075', '#b4bdce'],
          fill: true,
          datalabels: {
            color: colors.white.primary
          }
        }
      ]
    };

    var total_sum = parseFloat(response.data.reduce((a, b) => a + b, 0)).toLocaleString("ru");
    var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});

    Chart.helpers.each(Chart.instances, function(instance){
      if (instance.chart.canvas.id === 'chart-genders-count') {
        instance.chart.destroy()
      }
    })
    
    if (this.chart) {
      this.chart.destroy()
    }

    this.chart = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: options
    })
  }

  loading(){
    $(this.containerId()).addClass('loading');
  }

  loaded(){
    $(this.containerId()).removeClass('loading');
    this.chart.options.legend.position = 'right'
    this.chart.update()
  }

  elementId(){
    return '#chart-genders-count'
  }

  containerId(){
    return '#chart-genders-count-container'
  }
}

export default ChartGendersCount;
