import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, percentage_bar } from '../options';

class SkuTrands {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__sku-trands');
    let check = $('canvas').is('#charts__sku-trands');
    let project_id = $('[data-project-id]').data('project-id');

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/products_by_periods?project_id=' + project_id,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels,
          datasets: []
        };

        colors.reset()
        let counter = 0
        for(let period in response.periods){
          data.datasets.push({
            type: "bar",
            label: period,
            stack: "Stack " + counter,
            data: response.periods[period],
            backgroundColor: colors.getColor()[300],
            hoverBackgroundColor: colors.getColor()[500],
            borderColor: colors.getColor()[500],
            hoverBorderColor: colors.getColor()[500],
            datalabels: percentage_bar
          })

          colors.next()

          counter++;
        }

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: singleAxes
        });
      }
    }
  }

  static onInit() {
    SkuTrands.Chart();
  }
}

export default SkuTrands;
