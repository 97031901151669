import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, doubleAxes, value, percentage } from '../options';

class RegistedMembers {
  constructor() {
  }

  // callback returns the chart as Base64Image
  static Chart(callback) {
    let ctx = $('#charts__registed-members')
    let check = $('canvas').is('#charts__registed-members')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')
    let datasets = []
    let options = singleAxes

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/participants_by_periods?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.participants_actual.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')

          if (typeof callback == "function") {
            callback('not_enough_data');
          }

          return;
        }

        let actual_dataset = {
          yAxisID: "bars",
          type: "line",
          label: "Количество участников (факт за период)",
          data: response.participants_actual,
          backgroundColor: colors.blue[300],
          hoverBackgroundColor: colors.blue[500],
          borderColor: colors.blue[500],
          hoverBorderColor: colors.blue[500],
          fill: false,
          datalabels: value
        }

        datasets.push(actual_dataset)

        if (response.participants_plan){
          let plan_datasets = [
            {
              yAxisID: "bars",
              type: "line",
              label: "Количество участников (план за период)",
              data: response.participants_plan,
              backgroundColor: colors.violet[300],
              hoverBackgroundColor: colors.violet[500],
              borderColor: colors.violet[500],
              hoverBorderColor: colors.violet[500],
              fill: false,
              datalabels: value
            },
            {
              yAxisID: "lines",
              type: "scatter",
              label: "Количество участников от плана (в %)",
              data: response.plan_percentage,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              showLine: false,
              fill: false,
              datalabels: percentage
            }
          ]
          $.merge(datasets, plan_datasets)
          var maxTicks = Math.max(...response.plan_percentage) / 100
          maxTicks = Math.ceil(maxTicks) * 100;
          var yAxesOptions = Object.assign({}, doubleAxes)
          yAxesOptions['scales']['yAxes'][1]['ticks']['max'] = maxTicks
          options = yAxesOptions
        }

        if (response.history_datasets){
          colors.reset()
          let history_datasets = []
          $.each(response.history_datasets, function(){
            let color = colors.getFilteredColor(['yellow', 'green', 'orange'])
            history_datasets.push({
              yAxisID: "bars",
              type: "line",
              label: this.label,
              data: this.values,
              backgroundColor: color[300],
              hoverBackgroundColor: color[500],
              borderColor: color[500],
              hoverBorderColor: color[500],
              fill: false,
              datalabels: value
            })
            colors.next()
          })
          $.merge(datasets, history_datasets)
        }

        let data = {
          labels: response.labels,
          datasets: datasets
        };

        let animationOptions = {
          animation: {
            onComplete: function(){
              if (typeof callback == "function") {
                callback(newChart.toBase64Image());
              }
            }
          }
        }

        let newChart = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: { ...options, ...animationOptions }
        });

        let showValue = true
        let newValueOptions = {}

        $('#charts-registed-members-switch').on('click', function(){
          $(this).toggleClass('on')

          if(showValue == true)
          {
            showValue = false
            newValueOptions = {}
          }
          else
          {
            showValue = true
            newValueOptions = value
          }

          data.datasets.forEach(function(dataset) {
            dataset['datalabels'] = newValueOptions
          })

          newChart.update()
        })
      }
    }
  }

  static onInit() {
    RegistedMembers.Chart();
  }
}

export default RegistedMembers;
