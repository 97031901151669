import consumer from "./consumer"

window.cdpReportSubscription = function(reportId){
  consumer.subscriptions.create({ channel: "CdpReportChannel", report_id: reportId }, {
    connected() {
      console.log("Connected to the channel:", this);
    },

    disconnected() {
      console.log("Disconnected");
    },

    received: function(data) {
      console.log("Received some data:", data);
      let at = data.at;
      let id = data.id;
      $(`.js-cdp-report-progress.progress-field-${id}`).removeClass('d-none');
      $(`.js-cdp-report-progress.progress-field-${id}`).text(`${at}%`);
      if (at == 100) {
        let downloadPath = $('#cdp-report-download-path-template').text().replace('report_id', id);
        $(`a.js-cdp-report-${id}`).attr('href', downloadPath);
        $(`a.js-cdp-report-${id}`).attr('target', '_blank');
        // $(`js-cdp-report-${id} .js-cdp-report-progress`).removeClass('in_progress');
        $('.exports-count').removeClass('in-progress badge-light').addClass('badge-success');
        $('.exports-count').text('!');
        $(`tr.js-cdp-report-${id} .js-cdp-report-progress`).addClass('hidden');
        $(`tr.js-cdp-report-${id} a`).removeClass('hidden');
        $('#cdp-profiles-export-ready-toast').toast("show");
      }
    }
  });
}

$(document).on('ready', function() {
  $.each($('#last-exports .js-cdp-report-progress.in_progress'), function () {
    cdpReportSubscription($(this).data('report-id'))
  });
});
