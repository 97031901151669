import $ from 'jquery';


class ContragentRetailPlacesMap {
  constructor() {
  }

  static Chart() {
    let ctx = $('#contragent_retail_places_map');
    if (ctx.length == 0) return;

    let contragentId = $('#contragent_retail_places_map').data('contragent-id');
    let page = parseUrl('page');

    $.ajax({
      url: `/contragents/${contragentId}/map?page=${page}`,
      method: 'GET',
      dataType: 'json',
      success: function (response) {
        initChart(response)
      }
    });

    let initChart = function(response){
      $('#contragent_retail_places_map').vectorMap({
        map: 'ru_mill',
        markerStyle: {
          initial: {
            fill: '#304FFE',
            stroke: '#bbdefb'
          }
        },
        markers: response.markers,
        backgroundColor: '#006994',
        onMarkerTipShow: function(event, label, index){
          label.html(
            '<b>'+ response.markers[index]['name'] + '</b>'
          );
        }
      })
    }
  }

  static onInit() {
    ContragentRetailPlacesMap.Chart();
  }
}

export default ContragentRetailPlacesMap;
