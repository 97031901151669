import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, percentage_line } from '../options';

class PrizesPlayed {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__prizes-played')
    let check = $('canvas').is('#charts__prizes-played')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/prizes_played?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.labels == 0){
          $(ctx).parents('.card').remove()
          return;
        }

        let actual_dataset = {
          yAxisID: "bars",
          type: "bar",
          label: "Факт",
          stack: "Stack 0",
          data: response.actual,
          backgroundColor: colors.red[300],
          hoverBackgroundColor: colors.red[500],
          borderColor: colors.red[500],
          hoverBorderColor: colors.red[500],
          datalabels: {
            color: colors.white.primary
          }
        }

        datasets.push(actual_dataset)

        let data = {
          labels: response.labels,
          datasets: datasets
        }

        options['scales']['xAxes'][0]['ticks']['fontSize'] = 10

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    PrizesPlayed.Chart();
  }
}

export default PrizesPlayed;
