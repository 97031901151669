import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, doubleAxes, value, percentage } from '../options';

class RegistedMembersTotal {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-members-total')
    let check = $('canvas').is('#charts__registed-members-total')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')
    let datasets = []
    let options = singleAxes

    if(check) {
      $.ajax({
        url: `/api/v1/statistics/participants_total_by_periods?project_id=${project_id}&env=${env}`,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let actual_dataset = {
          yAxisID: "bars",
          type: "bar",
          label: "Количество участников (факт — накопительным итогом)",
          stack: "Stack 0",
          data: response.actual,
          backgroundColor: colors.blue[300],
          hoverBackgroundColor: colors.blue[500],
          borderColor: colors.blue[500],
          hoverBorderColor: colors.blue[500],
          datalabels: value
        }

        datasets.push(actual_dataset)

        if (response.plan){
          let plan_datasets = [
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество участников (план — всего)",
              stack: "Stack 1",
              data: response.plan,
              backgroundColor: colors.green[300],
              hoverBackgroundColor: colors.green[500],
              borderColor: colors.green[500],
              hoverBorderColor: colors.green[500],
              fill: false,
              datalabels: value
            },
            {
              yAxisID: "lines",
              type: "scatter",
              label: "Количество участников от плана (в %)",
              data: response.plan_percentage,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              showLine: false,
              fill: false,
              datalabels: percentage
            }
          ]
          $.merge(datasets, plan_datasets)
          var maxTicks = Math.max(...response.plan_percentage) / 100
          maxTicks = Math.ceil(maxTicks) * 100;
          var yAxesOptions = Object.assign({}, doubleAxes)
          yAxesOptions['scales']['yAxes'][1]['ticks']['max'] = maxTicks
          yAxesOptions['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
            return String(value) + '%'
          }
          options = yAxesOptions
        }

        let data = {
          labels: response.labels,
          datasets: datasets
        }

        options['legend']['align'] = 'start'

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    RegistedMembersTotal.Chart();
  }
}

export default RegistedMembersTotal;
