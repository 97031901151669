import $ from 'jquery';
import PerfectScrollbar from './perfect-scrollbar.min'
import lightSlider from './lightslider.min'
// require('perfect-scrollbar.min')
// require('lightslider.min')

class ticketsChat {
  constructor() {
    if ($('#tickets-chat').length) {
      this.init()
    }
  }

  init() {
    $('#chatActiveContacts').lightSlider({
      autoWidth: true,
      controls: false,
      pager: false,
      slideMargin: 12
    });

    if(window.matchMedia('(min-width: 992px)').matches) {
      new PerfectScrollbar('#azChatList', {
        suppressScrollX: true
      });

      const azChatBody = new PerfectScrollbar('#azChatBody', {
        suppressScrollX: true
      });

      $('#azChatBody').scrollTop($('#azChatBody').prop('scrollHeight'));
    }


    $('.az-chat-list .media').on('click touch', function() {
      $(this).addClass('selected').removeClass('new');
      $(this).siblings().removeClass('selected');

      if(window.matchMedia('(max-width: 991px)').matches) {
        $('body').addClass('az-content-body-show');
        $('html body').scrollTop($('html body').prop('scrollHeight'));
      }
    });
    if(window.matchMedia('(min-width: 991px)').matches){
      $('[data-toggle="tooltip"]').tooltip();
    }


    $('#azChatBodyHide').on('click touch', function(e){
      e.preventDefault();
      $('body').removeClass('az-content-body-show');
    })

  }

}

export default ticketsChat;