import cdpStatistics from '../packs/components/cdp/cdp_statistics';
import cdpInsights from '../packs/components/cdp/cdp_insights';
import $ from "jquery";

$('.with-sub').on('click', function(e) {
    e.preventDefault();
    $(this).parent().toggleClass('show');
    $(this).parent().siblings().removeClass('show');
});

$('#azSidebarToggle').on('click', function (e) {
    e.preventDefault();
    if (window.matchMedia('(min-width: 992px)').matches) {
        $('body').toggleClass('az-sidebar-hide');
    } else {
        $('body').toggleClass('az-sidebar-show');
    }
});

$('#onClickScrollToRight').on( 'click', function (e){
    let scrollLeft = e.currentTarget.offsetParent.querySelector('.scroll-container').scrollLeft
    let scrollWidth = e.currentTarget.offsetParent.querySelector('.scroll-container').scrollWidth
    $('.scroll-container').scrollLeft(scrollLeft+(scrollWidth/10))
})
$('#onClickScrollToLeft').on( 'click', function (e){
    let scrollLeft = e.currentTarget.offsetParent.querySelector('.scroll-container').scrollLeft
    let scrollWidth = e.currentTarget.offsetParent.querySelector('.scroll-container').scrollWidth
    $('.scroll-container').scrollLeft(scrollLeft-(scrollWidth/10))
})
$('#prize_kind').change(function () {
  if ($(this).val() == 'monetary') {
    $('#prize_external_source').val('giftery')
    $('#project-wallets-row').removeClass('d-none')
  } else {
    $('#prize_external_source').val('unknown')
    $('#project-wallets-row').addClass('d-none')
  }
})
$('#choose-wallet-kind-button').on( 'click', function (e){
  $('#wallet-modal').modal('show')
})
$("#project_wallet_form").bind('ajax:complete', function() {
  $('#wallet-modal').modal('hide')
  $('#save-success-project-wallet-modal').modal('show')
  $('#wallet-modal').load(location.href + " #wallet-modal > *");
  $('#project-wallets').load(location.href + " #project-wallets > *");
})
$('#wallet-modal').on('hidden.bs.modal', function () {
  $('#wallet-modal').load(location.href + ' #wallet-modal > *')
});
$('#interaction_params_algorithm').change(function () {
    $('#interaction_params_required_confirmed_numbers').val($('#interaction_params_algorithm :selected').data('required_confirmed_numbers'));
});
$("#lottery_version_v1").on( 'click', function (e){
  $('#v1_name').removeClass('d-none')
  $('#v2_category').addClass('d-none')
  $('#lottery_name').prop('required',true)
  reload_prize_select()
})
$("#lottery_version_v2").on( 'click', function (e){
  $('#v1_name').addClass('d-none')
  $('#v2_category').removeClass('d-none')
  $('#lottery_name').prop('required',false)
  reload_prize_select()
})
$("#lottery_category").on( 'change', function (e){
  reload_prize_select()
})
$("#lottery_prize_category").on( 'change' , function (e){
  reload_prize_select()
})
function reload_prize_select() {
  var version = $("input[name='lottery[version]']:checked").val()
  var path = $("input[name='lottery[version]']:checked").data('url')
  var url = ''
  if (version == 'v1') {
    path = path
  }else{
    path = path + '&prize_category=' + $("#lottery_prize_category option:selected").val()
  }
  $.ajax({
    url: path
  }).done(function(){
    $('#lottery_prize_id').val($('#lottery_selected_prize_id').val()).change()
  })
}
$('#block_list_member_project_id').on( 'change' , function(e) {
  $.ajax({
    url: location.href.replace('block_list_members', `set_current_project?project_id=${$("#block_list_member_project_id option:selected").val()}`)
  })
})
$('.lotteries-datefilter').on('change.datetimepicker', function(e) {
  $('#lotteries_table').DataTable().ajax.reload()
})
$('#filters_prize_id').on('change', function(e) {
  $('#lotteries_table').DataTable().ajax.reload()
})
$('#project_prices_tables').on('click', function(e){
  $('#prices-modal-depend-on-project').modal('show');
})
$('#prices_tables').on('click', function(e){
  $('#prices-modal-not-depend-on-project').modal('show');
})
$('.typcn.typcn-sort-numerically-outline').on('mouseover', function(e) {
  $(this).tooltip('show')
})
$('.typcn.typcn-sort-numerically-outline').on('mouseout', function(e) {
  $(this).tooltip('hide')
})

window.onload = function (e){
    for (let elem of $('.split-url')){
        if(elem.innerHTML!=='не указан'){
            let icon = elem.querySelector('a').querySelector('i')
            let url =  elem.querySelector('a').innerText
            elem.querySelector('a').innerHTML = url.substr(0, Math.floor((elem.offsetWidth - 25)/7)) + '...'
            elem.querySelector('a').append(icon)
        }
    }

    document.querySelectorAll('.project-card-with-link').forEach(el => {
        el.addEventListener('click', (e)=>{
            if(e.target.localName !== 'a'){
                window.open(el.dataset.projectDefaultLink, '_self')
            }
        })
    })


    let receiptsFiltersToggleButton =  document.createElement('button')
    receiptsFiltersToggleButton.className = 'btn btn-outline-light fas fa-filter py-0 ml-2 border-0 rounded-5 tx-18 tx-gray-700 my-auto'
    receiptsFiltersToggleButton.style.minHeight = '0'
    receiptsFiltersToggleButton.style.height = '33px'
    receiptsFiltersToggleButton.id  = 'receiptsFiltersToggleButton'
    receiptsFiltersToggleButton.addEventListener('click', ()=>{
        $('#allReceiptsFilterContainer').collapse('toggle')
        $('body').addClass('az-sidebar-hide');
        if(window.matchMedia('(max-width: 991px)').matches) {
            $('#allReceiptsFilterContainer').addClass('dropdown-menu')
            $('.az-header').addClass('d-none')
        } else {
            $('#receiptsFiltersToggleButton').toggleClass('btn-light').toggleClass('btn-outline-light')
        }
    })
    if(window.matchMedia('(max-width: 991px)').matches) {
        $('#project-receipts-list .card-header').append(receiptsFiltersToggleButton)
    } else {
        $('#receipts_table_filter').append(receiptsFiltersToggleButton).css('display', 'flex')
    }

    let allParticipantsFiltersToggleButton =  document.createElement('button')
    allParticipantsFiltersToggleButton.className = 'btn btn-outline-light fas fa-filter py-0 ml-2 border-0 rounded-5 tx-18 tx-gray-700'
    allParticipantsFiltersToggleButton.style.minHeight = '0'
    allParticipantsFiltersToggleButton.style.height = '33px'
    allParticipantsFiltersToggleButton.id  = 'allParticipantsFiltersToggleButton'
    allParticipantsFiltersToggleButton.addEventListener('click', ()=>{
        $('#allParticipantsFiltersToggleButton').toggleClass('btn-light').toggleClass('btn-outline-light')
        $('#allParticipantsFilterContainer').collapse('toggle')
        $('body').addClass('az-sidebar-hide');
    })
    $('#all-participants-table_filter').append(allParticipantsFiltersToggleButton).css('display', 'flex')

    $('#prize_distributionFiltersToggleButton').on('click', function (e){
        $('#prize_distributionToggleButton').toggleClass('btn-light').toggleClass('btn-outline-light')
        $('#prize_distributionFilterContainer').collapse('toggle')
        $('body').addClass('az-sidebar-hide');
    })

    $('.open-new-segment-modal').click(function(){
      $('#save-new-segment-modal').modal('show');
      $('#new-segment-query').val(JSON.stringify(SegmentQueryBuilder.getMongoQuery()));
    })

    $('.open-new-segment-from-file-modal').click(function(){
      $('#save-new-segment-from-file-modal').modal('show');
      $('#new-segment-query').val(JSON.stringify(SegmentQueryBuilder.getMongoQuery()));
    })

    $('.open-new-insight-segment-modal').click(function(){
      $('#save-new-insight-segment-modal').modal('show');
      $('#new-insight-segment-query').val(JSON.stringify(SegmentQueryBuilder.getMongoQuery()));
      $('#new-insight-segment-query-type').val($(this).data('scope'));
      $('#new-insight-segment-query-args').val($(this).data('args'))
    })

    $('.delete-segment-button').click(function(){
        $('#delete-segment-modal').modal('show')
        $('#delete-segment').attr('href', $(this).data("url"))
    })

    $("#query-builder-modal .btn-primary").click(function(){
        new cdpStatistics()
        new cdpInsights()
    })

    $("#query-builder-modal .btn-secondary").click(function(){
        new cdpStatistics()
        new cdpInsights()
    })
    if ($('#lottery_version_v2').is(':checked')) {
      reload_prize_select()
    }

    $('#cdp-profiles-export-status-toast, #cdp-profiles-export-ready-toast').on('show.bs.toast', function () {
      $(this).removeClass('d-none')
    })

    $('#cdp-profiles-export-status-toast, #cdp-profiles-export-ready-toast').on('hidden.bs.toast', function () {
      $(this).addClass('d-none')
    })
}