import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, value, percentage } from '../options';

class RegistedCodesTotal {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-codes-total');
    let check = $('canvas').is('#charts__registed-codes-total');
    let project_id = $('[data-project-id]').data('project-id');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');
    let datasets = [];

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_by_periods?project_id=' + project_id + '&start_week=' + first_week + '&end_week=' + end_week,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.valid_codes_total_promo == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let actual_dataset = {
          yAxisID: "bars",
          type: "bar",
          label: "Количество валидных кодов (факт — накопительным итогом)",
          stack: "Stack 0",
          data: response.valid_codes_total_promo,
          backgroundColor: colors.yellow[300],
          hoverBackgroundColor: colors.yellow[500],
          borderColor: colors.yellow[500],
          hoverBorderColor: colors.yellow[500],
          datalabels: value
        }

        datasets.push(actual_dataset);

        if (response.valid_codes_plan_total_promo){
          let plan_datasets = [
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество валидных кодов (план — всего)",
              stack: "Stack 1",
              data: response.valid_codes_plan_total_promo,
              backgroundColor: colors.green[300],
              hoverBackgroundColor: colors.green[500],
              borderColor: colors.green[500],
              hoverBorderColor: colors.green[500],
              datalabels: value
            },
            {
              type: "scatter",
              label: "Процент от плана (всего)",
              data: response.percentage_total_promo,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              showLine: false,
              fill: false,
              datalabels: percentage
            }
          ]
          $.merge(datasets, plan_datasets)
        }

        let data = {
          labels: response.labels_total_promo,
          datasets: datasets
        };

        var options = Object.assign({}, doubleAxes);
        options['scales']['yAxes'][1]['ticks']['max'] = 100;
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value) + '%';
        };
        options['legend']['align'] = 'start'

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    RegistedCodesTotal.Chart();
  }
}

export default RegistedCodesTotal;
