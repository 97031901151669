import $ from 'jquery';

class TopTrafficInfo{
  constructor() {
    if ($('#top_traffic_info').length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        $.ajax({
          url: $('#top_traffic_info').data('url'),
          method: 'GET',
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    var i = 1
    response.forEach((e) => {
      $(`#top_traffic_info #promo_name_${i}`).text(`${e.host}`)
      $(`#top_traffic_info #promo_count_${i}`).html(`${e.count} <span>(${e.percentage}%)</span>`)
      $(`#top_traffic_info #progress_bar_${i}`).css('width', `${e.percentage}%`)
      $(`#top_traffic_info #progress_bar_${i}`).attr('aria-valuenow', e.percentage)

      i++
    })
    
    this.loaded();
  }

  loading(){
    $('#top_traffic_info').addClass('loading');
  }

  loaded(){
    $('#top_traffic_info').removeClass('loading');
  }
}

export default TopTrafficInfo;