import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, percentage_line } from '../options';

class SkuAllocation {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__sku-allocation');
    let check = $('canvas').is('#charts__sku-allocation');
    let project_id = $('[data-project-id]').data('project-id');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_by_products?project_id=' + project_id + '&start_week=' + first_week + '&end_week=' + end_week,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        let data = {
          labels: response.labels,

          datasets: [
            {
              yAxisID: "lines",
              type: "line",
              label: "Процент",
              data: response.percentage,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              fill: false,
              showLine: false,
              datalabels: percentage_line
            },
            {
              yAxisID: "bars",
              type: "bar",
              label: "Валидные",
              stack: "Stack 0",
              data: response.valid,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500],
              datalabels: {
                color: colors.white.primary
              }
            },
            {
              yAxisID: "bars",
              type: "bar",
              label: "Невалидные",
              stack: "Stack 1",
              data: response.invalid,
              backgroundColor: colors.red[300],
              hoverBackgroundColor: colors.red[500],
              borderColor: colors.red[500],
              hoverBorderColor: colors.red[500],
              datalabels: {
                color: colors.white.primary
              }
            }
          ]
        }

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: doubleAxes
        });
      }
    }
  }

  static onInit() {
    SkuAllocation.Chart();
  }
}

export default SkuAllocation;
