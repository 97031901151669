import { colors } from './colors';

export let pie = {
  responsive: true,

  legend: {
    display: true,

    labels: {
      fontColor: colors.black.primary,
      fontFamily: "Roboto",
      generateLabels: function(chart) {
        var data = chart.data;
        if (data.labels.length && data.datasets.length) {
          return data.labels.map(function(label, i) {
            var meta = chart.getDatasetMeta(0);
            var ds = data.datasets[0];
            var arc = meta.data[i];
            var custom = arc && arc.custom || {};
            var getValueAtIndexOrDefault = Chart.helpers.getValueAtIndexOrDefault;
            var arcOpts = chart.options.elements.arc;
            var fill = custom.backgroundColor ? custom.backgroundColor : getValueAtIndexOrDefault(ds.backgroundColor, i, arcOpts.backgroundColor);
            var stroke = custom.borderColor ? custom.borderColor : getValueAtIndexOrDefault(ds.borderColor, i, arcOpts.borderColor);
            var bw = custom.borderWidth ? custom.borderWidth : getValueAtIndexOrDefault(ds.borderWidth, i, arcOpts.borderWidth);

            // We get the value of the current label
            var value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

            // Count percentage
            let amount = 0;
            let dataArray = chart.config.data.datasets[0].data;
            dataArray.map(data => {
              amount += data;
            });
            let percentage = parseFloat((value * 100 / amount).toFixed(2)).toLocaleString("ru") + '%';
            let output = label + " : " + String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' / ' + percentage;

            if(chart.config.data.datasets[arc._datasetIndex].onlyPercentage === true)
            {
              output = percentage + '%';
            }

            return {
              // Instead of `text: label,`
              // We add the value to the string
              text: output,
              fillStyle: fill,
              strokeStyle: stroke,
              lineWidth: bw,
              hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
              index: i
            };
          });
        } else {
          return [];
        }
      }
    }
  },

  tooltips: {
    mode: "index",
    intersect: true,
    callbacks: {
      // this callback is used to create the tooltip label
      label: function(tooltipItem, data) {
        // get the data label and data value to display
        // convert the data value to local string so it uses a comma seperated number
        var dataLabel = data.labels[tooltipItem.index];
        var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
        if (Chart.helpers.isArray(dataLabel)) {
          // show value on first line of multiline label
          // need to clone because we are changing the value
          dataLabel = dataLabel.slice();
          dataLabel[0] += value;
        } else {
          dataLabel += value;
        }

        // return the text to display on the tooltip
        return dataLabel;
      }
    }
  },

  plugins: {
    datalabels: {
      color: colors.transparent,
      textAlign: "center",
      display: 'auto',

      font: {
        family: "Roboto",
        weight: "700"
      },

      formatter: function(value, ctx) {
        let amount = 0;
        let dataArray = ctx.chart.data.datasets[0].data;

        dataArray.map(data => {
          amount += data;
        });

        let percentage = parseFloat((value * 100 / amount).toFixed(2)).toLocaleString("ru") + '%';
        let output = String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ") + "\n" + percentage + "%";

        if(ctx.chart.data.datasets[0].onlyPercentage === true)
        {
          output = percentage + "%";
        }

        return output;
      }
    }
  }
};

export let doubleAxes = {
  responsive: true,

  legend: {
    display: true,

    labels: {
      fontColor: colors.black.primary,
      fontFamily: "Roboto"
    }
  },

  tooltips: {
    mode: "index",
    intersect: true,
    callbacks: {
      // this callback is used to create the tooltip label
      label: function(tooltipItem, data) {
        // get the data label and data value to display
        // convert the data value to local string so it uses a comma seperated number
        var dataLabel = data.labels[tooltipItem.index];
        var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var currentTitle = data.datasets[tooltipItem.datasetIndex].label;

        // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
        if (Chart.helpers.isArray(dataLabel)) {
          // show value on first line of multiline label
          // need to clone because we are changing the value
          dataLabel = dataLabel.slice();
          dataLabel[0] += value;
        } else {
          dataLabel += value;
        }

        if(currentTitle.includes('Процент'))
        {
          dataLabel += '%';
        }

        // return the text to display on the tooltip
        return dataLabel;
      }
    }
  },

  scales: {
    xAxes: [
      {
        ticks: {
          padding: 15,

          fontColor: colors.black.secondary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          suggestedMin: 0
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      }
    ],

    yAxes: [
      {
        id: "bars",
        position: "left",

        ticks: {
          padding: 5,

          fontColor: colors.black.primary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          maxTicksLimit: 5,
          suggestedMin: 0,
          callback: function(value, index, values) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      },
      {
        id: "lines",
        type: "linear",
        position: "right",

        ticks: {
          padding: 5,

          fontColor: colors.black.primary,
          fontFamily: "Roboto",
          fontStyle: "700",

          maxTicksLimit: 5,
          beginAtZero: true,
          suggestedMin: 0,
          callback: function(value, index, values) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },

        gridLines: {
          display: false
        }
      }
    ]
  },

  plugins: {
    datalabels: {
      color: colors.transparent,

      font: {
        family: "Roboto",
        weight: "700"
      },

      formatter: function(value, ctx) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }
  }
}

export let singleAxes = {
  responsive: true,

  legend: {
    display: true,

    labels: {
      fontColor: colors.black.primary,
      fontFamily: "Roboto"
    }
  },

  tooltips: {
    mode: "index",
    intersect: true,
    callbacks: {
      // this callback is used to create the tooltip label
      label: function(tooltipItem, data) {
        // get the data label and data value to display
        // convert the data value to local string so it uses a comma seperated number
        var dataLabel = data.labels[tooltipItem.index];
        var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        // make this isn't a multi-line label (e.g. [["label 1 - line 1, "line 2, ], [etc...]])
        if (Chart.helpers.isArray(dataLabel)) {
          // show value on first line of multiline label
          // need to clone because we are changing the value
          dataLabel = dataLabel.slice();
          dataLabel[0] += value;
        } else {
          dataLabel += value;
        }

        // return the text to display on the tooltip
        return dataLabel;
      }
    }
  },

  scales: {
    xAxes: [
      {
        ticks: {
          padding: 15,

          fontColor: colors.black.secondary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          suggestedMin: 0
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      }
    ],

    yAxes: [
      {
        id: "bars",
        position: "left",

        ticks: {
          padding: 5,

          fontColor: colors.black.primary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          maxTicksLimit: 5,
          suggestedMin: 0,
          callback: function(value, index, values) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      },
    ]
  },

  plugins: {
    datalabels: {
      color: colors.transparent
    }
  }
}

export let horizontalLabels = {
  responsive: true,

  legend: {
    display: true,

    labels: {
      fontColor: colors.black.primary,
      fontFamily: 'Roboto'
    }
  },

  tooltips: {
    mode: 'index',
    intersect: true
  },

  scales: {
    xAxes: [
      {
        ticks: {
          padding: 15,

          fontColor: colors.black.secondary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          suggestedMin: 0,
          callback: function(value, index, values) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      }
    ],

    yAxes: [
      {
        id: "bars",
        position: "left",

        ticks: {
          padding: 5,

          fontColor: colors.black.primary,
          fontFamily: "Roboto",
          fontStyle: "700",

          beginAtZero: true,
          suggestedMin: 0,
          callback: function(value, index, values) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          }
        },

        gridLines: {
          zeroLineColor: colors.transparent,
          drawTicks: false
        }
      },
    ]
  },

  plugins: {
    datalabels: {
      anchor: 'end',
      font: {
        weight: 'bold'
      },
      formatter: function(value) {
        return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }
  }
}

export let value = {
  color: colors.white.primary,
  align: "center",
  borderRadius: "4",

  backgroundColor: function(context) {
    if(context.dataset.borderColor) {
      return context.dataset.borderColor;
    } else {
      return context.dataset.hoverBorderColor;
    }
  },

  formatter: function(value) {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
}

export let percentage = {
  color: colors.white.primary,
  align: "top",
  borderRadius: "4",

  backgroundColor: function(context) {
    if(context.dataset.borderColor) {
      return context.dataset.borderColor;
    } else {
      return context.dataset.hoverBorderColor;
    }
  },

  formatter: function(value) {
    return value + "%";
  }
}

export let percentage_line = {
  color: colors.white.primary,
  align: "top",
  borderRadius: "4",

  backgroundColor: function(context) {
    if(context.dataset.borderColor) {
      return context.dataset.borderColor;
    } else {
      return context.dataset.hoverBorderColor;
    }
  },

  formatter: function(value) {
    return value + "%";
  }
}

export let percentage_bar = {
  color: colors.white.primary,

  formatter: function(value) {
    return value + "%";
  }
}
