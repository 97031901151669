$(document).ready(function(){
  if($('#show-landing').length) {
    if($('#lk').length) {
      var url = $('#lk').data('url')

      $.get(url)
    }

    if($('#winners').length) {
      var url = $('#winners').data('url')

      $.get(url)
    }

    $('.show-signin-modal').click(function(){
      closeAllModals()

      $('#signin-modal').modal('show');
    })

    $('.show-password-recovery-modal').click(function(){
      closeAllModals()

      $('#password-recovery-modal').modal('show');
    })

    $('.show-signup-modal').click(function(){
      closeAllModals()

      $('#signup-modal').modal('show');
    })

    $('.show-tickets-modal').click(function(){
      closeAllModals()

      $('#tickets-modal').modal('show');
    })

    $('.show-receipts-modal').click(function(){
      closeAllModals()

      $('#receipts-modal').modal('show');
    })

    $('.show-codes-modal').click(function(){
      closeAllModals()

      $('#codes-modal').modal('show');
    })

    $('#signup-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "POST",
        url: url,
        data: data,
        dataType: 'JSON',
      }).done(function(response) {
        var confirmationType = $('#confirmation_type').data("type")

        closeAllModals()

        if(confirmationType == 'phone') {
          $("#confirmation-by-phone-modal").modal('show');
          var login = $('input#participant_email').val();
          $("#confirmation-by-phone-form input#engage_login").val(login)
        } else {
          $("#confirmation-by-email-modal").modal('show');
        }
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#signup-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('#signin-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "POST",
        url: url,
        data: data,
        dataType: 'JSON',
        headers: {
          'Api-key': $('#api_key').data("api-key")
        }
      }).done(function(response) {
        // Do not forget to remove cookies after sign out
        document.cookie = "participantToken=" + response['token'];
        document.cookie = "participantId=" + response['participant']['id'];
        document.cookie = "participantEmail=" + response['participant']['email'];
        document.cookie = "participantPhone=" + response['participant']['phone'];
        document.cookie = "engageId=" + response['engage']['id'];
        document.cookie = "engageToken=" + response['engage']['token'];

        window.location.reload()
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#signin-form input[type='submit']").prop('disabled', false)

        if(errorText == 'Вам необходимо подтвердить свой аккаунт.') {
          sendConfirmation($('#signin-form input#login').val())
        } else {
          alert("Ошибка: " + errorText);
        }
      });
    })

    // Sign out action
    $('.signout-button').click(function(){
      clearCookies()

      window.location.reload()
    })

    $('#confirmation-by-phone-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "GET",
        url: url,
        data: data,
        dataType: 'JSON',
        headers: {
          'Api-key': $('#api_key').data("api-key")
        }
      }).done(function(response) {
        closeAllModals()
        $('#signin-modal').modal('show');

        alert('Код успешно подтвержден. Теперь вы можете войти в свой аккаунт.');
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#confirmation-by-phone-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('#confirmation-by-email-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "GET",
        url: url,
        data: data,
        dataType: 'JSON'
      }).done(function(response) {
        closeAllModals()
        $('#signin-modal').modal('show');

        alert('Код успешно подтвержден. Теперь вы можете войти в свой аккаунт.');
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#confirmation-by-email-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('#password-recovery-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "POST",
        url: url,
        data: data,
        dataType: 'JSON',
        headers: {
          'Api-key': $('#api_key').data("api-key")
        }
      }).done(function(response) {
        var email = $('#password-recovery-form input#participant_email').val()

        closeAllModals()

        $('#new-password-form input#participant_email').val(email)
        $('#new-password-modal').modal('show');
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#password-recovery-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('#new-password-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: "PUT",
        url: url,
        data: data,
        dataType: 'JSON',
        headers: {
          'Api-key': $('#api_key').data("api-key")
        }
      }).done(function(response) {
        alert('Пароль обновлен. Теперь можете войти в аккаунт')

        window.location.reload()
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#new-password-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('#tickets-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: 'POST',
        url: url,
        data: data,
        dataType: 'JSON'
      }).done(function(response) {
        alert('Вопрос отправлен');

        window.location.reload()
      }).fail(function(response){
        errorText = getErrorText(response)

        $("#tickets-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })

    $('.receipts-form').submit(function(e){
      e.preventDefault()
      var url = $(this).attr('action')

      if($(this).find('input#images_image').length) {
        var data = new FormData();
        data.append('images[][image]', $('input#images_image')[0].files[0]);

        var ajaxConfig = {
          method: 'POST',
          url: url,
          data: data,
          cache: false,
          contentType: false,
          processData: false,
          headers: {
            Authorization: 'Token ' + $('#engage_token').data("value"),
            'Api-key': $('#api_key').data("api-key"),
          },
          statusCode: {
            401: function() {
              alert("Войдите в аккаунт");

              clearCookies()

              window.location.reload()
            }
          }
        }

        $('.receipts-form .notes b').text('отправляется ...')
      } else {
        var data = $(this).serializeArray()
        var ajaxConfig = {
          method: 'POST',
          url: url,
          data: data,
          headers: {
            Authorization: 'Token ' + $('#engage_token').data("value"),
            'Api-key': $('#api_key').data("api-key"),
          },
          statusCode: {
            401: function() {
              alert("Войдите в аккаунт");

              clearCookies()

              window.location.reload()
            }
          }
        }
      }

      $.ajax(ajaxConfig).done(function(response) {
        alert('Чек загружен');

        window.location.reload()
      }).fail(function(response){
        errorText = getErrorText(response)

        $(".receipts-form input[type='submit']").prop('disabled', false)
        $('.receipts-form .notes b').text('')

        alert("Ошибка: " + errorText);
      });
    })

    $('#codes-form').submit(function(e){
      e.preventDefault()

      var url = $(this).attr('action')
      var data = $(this).serializeArray()

      $.ajax({
        method: 'POST',
        url: url,
        data: data,
        headers: {
          Authorization: 'Token ' + $('#participant_token').data("value"),
          'Api-key': $('#api_key').data("api-key"),
        },
        statusCode: {
          401: function() {
            alert("Войдите в аккаунт");

            clearCookies()

            window.location.reload()
          }
        }
      }).done(function(response) {
        alert('Код загружен');

        window.location.reload()
      }).fail(function(response){
        errorText = getErrorText(response)
        if(errorText == 'code is duplicate') {
          errorText = 'Дубликат'
        }
        if(errorText == 'incorrect_code') {
          errorText = 'Неверный код'
        }

        $("#codes-form input[type='submit']").prop('disabled', false)

        alert("Ошибка: " + errorText);
      });
    })
  }
});

function sendConfirmation(login) {
  var url = $('#confirmation_url').data("url")
  var confirmationType = $('#confirmation_type').data("type")
  var login = login

  if(confirmationType == 'phone') {
    var data = { engage: { login: login } }
  } else {
    var data = { email: login }
  }

  $.ajax({
    method: "POST",
    url: url,
    data: data,
    dataType: 'JSON',
    headers: {
      'Api-key': $('#api_key').data("api-key")
    },
    statusCode: {
      429: function() {
        alert("Слишком много запросов. Пожалуйста подождите.");
      }
    }
  }).done(function(response) {
    closeAllModals()

    $("#confirmation-by-phone-modal").modal('show');
    $("#confirmation-by-phone-form input#engage_login").val(login)
  }).fail(function(response){
    alert("Произошла ошибка при попытке отправить повторное подтверждение");
  });
}

function closeAllModals() {
  $('.modal').modal('hide')
}

function clearCookies() {
  document.cookie = "participantToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = "participantId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = "participantEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = "participantPhone=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = "engageId=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
  document.cookie = "engageToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
}

function getErrorText(response) {
  var message = 'Ошибка'

  if (response.hasOwnProperty('statusText')) {
    if (response.statusText == 'timeout') {
      message = 'Не удалось отправить данные. Пожалуйста, попробуйте позднее.';
    }
  }

  if (response.hasOwnProperty('responseJSON')) {
    if (response.responseJSON != undefined) {
      var responseMessage = response.responseJSON.message;
      var responseErrors = response.responseJSON.errors;

      if (typeof responseMessage === 'string' || responseMessage instanceof String) {
        message = responseMessage;
      } else if (typeof responseErrors === 'string' || responseErrors instanceof String) {
        message = responseErrors;
      } else {
        message = '';
        var label = '';
        var errorFields = [];
        var errorCodes = [];

        for (var key in response.responseJSON.message) {
          errorFields.push(key);
          errorCodes.push(response.responseJSON.message[key]);
          label = localLabel(key);
          message += label + ': ' + response.responseJSON.message[key] + '; ';
        }
      }
    }
  } else if (response.hasOwnProperty('responseText')) {
    message = response.responseText;
  }

  if (message.trim() == '') {
    message = 'Возникла непредвиденная ошибка. Пожалуйста, попробуйте позднее.';
  }

  return message;
}

function localLabel(key) {
  var label = '';
  switch (key) {
    case 'email':
      label = 'Email';
      break;
    case 'password':
      label = 'Пароль';
      break;
    case 'password_confirmation':
      label = 'Пароль';
      break;
    case 'last_name':
      label = 'Фамилия';
      break;
    case 'first_name':
      label = 'Имя';
      break;
    case 'middle_name':
      label = 'Отчество';
      break;
    case 'phone':
      label = 'Телефон';
      break;
    case 'terms_of_use':
      label = 'Пользовательское соглашение';
      break;
    case 'promo_rules':
      label = 'Правила акции';
      break;
    case 'personal_data':
      label = 'Персональные данные';
      break;
    case 'confirmation_code':
      label = 'Код подтверждения';
      break;
    default:
      label = key;
      break;
  }
  return label;
}
