import Glide from '@glidejs/glide';

window.Receipts = {
  glide: null,

  init: function () {
    $('#checks-check__tab_moderator').on('click', () => {
      setTimeout(() => {
        $('.receipts-glider').trigger("click");
      }, 500)
    })


    $(document).on('mouseenter touchstart click', '.receipts-glider', function () {
      if (!$('.receipts-glider').hasClass('bound')) {
        this.glide = new Glide('.receipts-glider ', {
          dragThreshold: false,
          keyboard: false,
          swipeThreshold: false,
        })

        let thisGlide = this.glide
        document.querySelector('.glide__arrow--right').addEventListener('click', function (event) {
          event.preventDefault();

          thisGlide.go('>');
        })

        document.querySelector('.glide__arrow--left').addEventListener('click', function (event) {
          event.preventDefault();

          thisGlide.go('<');
        })

        wheelzoom(document.querySelectorAll('.receipt-image'),{
          zoomOnClick: false,
          height: '100%',
          speed: ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0) ? 3 : 1,
        });

        this.glide.mount()
        $('.receipts-glider').addClass('bound')
      }
    })
  }
}
