import $ from 'jquery';
import { colors } from '../colors';
import { doubleAxes, value, percentage } from '../options';

class RegistedCodesPeriod {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registed-codes-period');
    let check = $('canvas').is('#charts__registed-codes-period');
    let project_id = $('[data-project-id]').data('project-id');
    let datasets = [];

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_by_periods?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.labels_last_week.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let actual_dataset = {
          yAxisID: "bars",
          type: "bar",
          label: "Количество валидных кодов (факт за период)",
          data: response.valid_codes_actual_last_week,
          backgroundColor: colors.yellow[300],
          hoverBackgroundColor: colors.yellow[500],
          borderColor: colors.yellow[500],
          hoverBorderColor: colors.yellow[500],
          fill: false,
          datalabels: value
        }

        datasets.push(actual_dataset);

        if (response.codes_plan_last_week){
          let plan_datasets = [
            {
              yAxisID: "bars",
              type: "bar",
              label: "Количество валидных кодов (план за период)",
              data: response.codes_plan_last_week,
              backgroundColor: colors.green[300],
              hoverBackgroundColor: colors.green[500],
              borderColor: colors.green[500],
              hoverBorderColor: colors.green[500],
              fill: false,
              datalabels: value
            },
            {
              type: "scatter",
              label: "Процент от плана",
              data: response.plan_percentage_last_week,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              pointBackgroundColor: colors.orange[300],
              hoverPointBackgroundColor: colors.orange[500],
              pointBorderColor: colors.orange[500],
              hoverPointBorderColor: colors.orange[500],
              showLine: false,
              fill: false,
              datalabels: percentage
            }
          ]
          $.merge(datasets, plan_datasets)
        }

        let data = {
          labels: response.labels_last_week,
          datasets: datasets
        };

        var options = Object.assign({}, doubleAxes);
        options['scales']['yAxes'][1]['ticks']['max'] = 100;
        options['scales']['yAxes'][1]['ticks']['callback'] = function(value, index, values) {
          return String(value) + '%';
        };
        options['legend']['align'] = 'start'

        new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
      }
    }
  }

  static onInit() {
    RegistedCodesPeriod.Chart();
  }
}

export default RegistedCodesPeriod;
