import $ from 'jquery';

class cdpStatisticsActiveParticipants{
  constructor() {
    if ($(this.elementId()).length > 0){
      this.loading()
      this.init()
    }
  }

  init(){
    var cdpStat = this;

    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $(cdpStat.elementId()).data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $(this.elementId() + ' #counts').text(`${this.transform(response.new_participants_current_period)} (${this.transform(response.profiles_percentage)}%)`);
    var badgeText = ''
    var badgeClassName = ''
    
    if (response.badge) {
      if (response.difference >= 0) { 
        badgeText = `+${this.transform(response.difference)}%`
        badgeClassName = 'up'
      }else{
        badgeText = `${this.transform(response.difference)}%`
        badgeClassName = 'down'
      }

      $(this.elementId() + ' small').text(badgeText)
      $(this.elementId() + ' small').removeClass('down')
      $(this.elementId() + ' small').addClass(badgeClassName)
    } else {
      $(this.elementId() + ' small').addClass('d-none')
    }

    this.loaded();
  }

  loading(){
    $(this.elementId()).addClass('loading');
  }

  loaded(){
    $(this.elementId()).removeClass('loading');
  }

  elementId(){
    return '#cdp-stat-active-participants'
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsActiveParticipants;