import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import MainAnalyticsCommonDynamics from './components/charts/main_analytics/common_dynamics';
import RegistedMembers from './components/charts/registed-members/registed-members';
import RegistedCodes from './components/charts/registed-codes/registed-codes';
import RegistedReceipts from './components/charts/registed-receipts/registed-receipts';
import RegisteredMembersMilVsAll from './components/charts/geo/registered-members-mil-vs-all.js';
import PrizesSelected1 from './components/charts/prizes-selected/prizes-selected_1.js';

$(document).on('click', '.report-link a', function(e){
  e.preventDefault()

  let period_filter_type = parseUrl('period_filter_type')

  if(period_filter_type)
  {
    let form = $('form#dashboard_reports_form')

    $('#dashboard-report-notice-modal').modal();

    mainAnalyticsChart(form, function(){
      audienceChart(form, function(){
        codesOrReceiptsChart(form, function(){
          geoChart(form, function(){
            prizesChart(form, function(){
                form.submit();
            });
          });
        });
      });
    });
  }
  else
  {
    alert('Выберите фильтр - по дням или по неделям')
  }
});

function mainAnalyticsChart(form, callback)
{
  var elemId = 'charts_main_analytics_common_dynamics'
  if($('#' + elemId).length == 0){
    $('body').prepend(chartContainer(elemId))
  }

  MainAnalyticsCommonDynamics.Chart(function(data){
    form.find('input#main_analytics_image').val(data);
    removeChartContainer();
    callback();
  });
}

function audienceChart(form, callback)
{
  var elemId = 'charts__registed-members'
  if($('#' + elemId).length == 0){
    $('body').prepend(chartContainer(elemId))
  }

  RegistedMembers.Chart(function(data){
    form.find('input#audience_chart_image').val(data);
    removeChartContainer();
    callback();
  });
}

function codesOrReceiptsChart(form, callback)
{
  var projectKind = $('#dashboard-reports-settings').data('kind')

  if(projectKind =='codes')
  {
    codesChart(form, callback)
  }

  if(projectKind =='receipts')
  {
    receiptsChart(form, callback)
  }
}

function codesChart(form, callback)
{
  var elemId = 'charts__registed-codes'
  if($('#' + elemId).length == 0){
    $('body').prepend(chartContainer(elemId))
  }

  RegistedCodes.Chart(function(data){
    form.find('input#codes_chart_image').val(data);
    removeChartContainer();
    callback();
  });
}

function receiptsChart(form, callback)
{
  var elemId = 'charts__registed-receipts'
  if($('#' + elemId).length == 0){
    $('body').prepend(chartContainer(elemId))
  }

  RegistedReceipts.Chart(function(data){
    form.find('input#receipts_chart_image').val(data);
    removeChartContainer();
    callback();
  });
}

function geoChart(form, callback)
{
  var elemId = 'charts__registered-members-mil-vs-all'
  if($('#' + elemId).length == 0){
    $('body').prepend(chartContainer(elemId))
  }

  RegisteredMembersMilVsAll.Chart(function(data){
    form.find('input#geo_chart_image').val(data);
    removeChartContainer();
    callback();
  });
}

function prizesChart(form, callback)
{
  var projectKind = $('#dashboard-reports-settings').data('kind')

  if(projectKind == 'codes')
  {
    var elemId = 'charts__prizes-selected_1'
    if($('#' + elemId).length == 0){
      $('body').prepend(chartContainer(elemId))
    }

    PrizesSelected1.Chart(function(data){
      form.find('input#prizes_chart_image').val(data);
      removeChartContainer();
      callback();
    });
  }
  else
  {
    callback();
  }
}

function chartContainer(id)
{
  return "<div id='dynamic_chart' class='chart'><canvas id='" + id + "' class='chartjs-render-monitor'></div>"
}

function removeChartContainer()
{
  $('#dynamic_chart').remove();
}
