import $ from 'jquery';
import { colors } from '../../charts/colors';
import {pie, singleAxes, value} from "../../charts/options";

class cdpStatisticsActivityStats{
  constructor() {
    if ($('#cdp-stat-activity-stats').length > 0){
      var _this = this
      this.loading()
      this.init()
      setInterval(function() {
        _this.onlineProfiles()
      }, 10000);
    }
  }

  init(){
    var cdpStat = this;
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-stat-activity-stats').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
            cdpStat.initChart(response)
          }
        });
      }, 1000);
    })
  }

  onlineProfiles(){
    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };
        $.ajax({
          url: $('#cdp-stat-activity-stats #online_profiles').data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            $('#cdp-stat-activity-stats #online_profiles').text(`${response.online_profiles}`);
          }
        });
      }, 1000);
    })
  }

  initChart(response){
    $('#cdp-stat-activity-stats .chart').css('height', 335);

    let ctx = $('#cdp-stat-activity-stats #chart_activity_stats');
    
    let data = {
      labels: response.labels,

      datasets: [
        {
          yAxisID: "bars",
          type: "line",
          label: 'Вчера',
          data: response.active_yesterday_hourly,
          backgroundColor: '#b4bdce',
          hoverBackgroundColor: '#b4bdce',
          borderColor: '#b4bdce',
          hoverBorderColor: '#b4bdce',
          fill: false,
          datalabels: value
        },
        {
          yAxisID: "bars",
          type: "line",
          label: 'Сегодня',
          data: response.active_today_hourly,
          backgroundColor: '#f10075',
          hoverBackgroundColor: '#f10075',
          borderColor: '#f10075',
          hoverBorderColor: '#f10075',
          fill: false,
          datalabels: value
        }
      ]
    };

    Chart.helpers.each(Chart.instances, function(instance){
      if (instance.chart.canvas.id === 'chart_activity_stats') {
        instance.chart.destroy()
      }
    })

    this.chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: data.labels,
        datasets: data.datasets
      },
      options: singleAxes
    })

    let showValue = true
    let newValueOptions = {}

    let thisChart = this.chart

    $('#chart_activity_stats-switch').on('click', function(){
      $(this).toggleClass('on')

      if(showValue == true)
      {
        showValue = false
        newValueOptions = {}
      }
      else
      {
        showValue = true
        newValueOptions = value
      }

      data.datasets.forEach(function(dataset) {
        dataset['datalabels'] = newValueOptions
      })

      thisChart.update()
    })
  }

  renderStats(response){
    $('#cdp-stat-activity-stats #total_count').text(`${this.transform(response.total_profiles_count)}`);
    $('#cdp-stat-activity-stats #active_today').text(`${this.transform(response.active_today)}`);
    $('#cdp-stat-activity-stats #active_yesterday').text(`${this.transform(response.active_yesterday)}`);
    $('#cdp-stat-activity-stats #online_profiles').text(`${this.transform(response.online_profiles)}`);
    $('#cdp-stat-activity-stats #active_after_dinner').text(`${this.transform(response.active_after_dinner)} (${this.transform(response.active_after_dinner_percentage)}%)`);
    $('#cdp-stat-activity-stats #active_before_afternoon').text(`${this.transform(response.active_before_afternoon)} (${this.transform(response.active_before_afternoon_percentage)}%)`);
    $('#cdp-stat-activity-stats #active_another_time').text(`${this.transform(response.active_another_time)} (${this.transform(response.active_another_time_percentage)}%)`);
    this.loaded();
  }

  loading(){
    $('#cdp-stat-activity-stats').addClass('loading');
  }

  loaded(){
    $('#cdp-stat-activity-stats').removeClass('loading');
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsActivityStats;