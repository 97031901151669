import $ from 'jquery';
import { colors } from '../colors';
import { pie } from '../options';

class IncorrectReceipts2 {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__incorrect-receipts_2');
    let check = $('canvas').is('#charts__incorrect-receipts_2');
    let project_id = $('[data-project-id]').data('project-id');
    let env = $('[data-env]').data('env');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: `/api/v1/statistics/receipts_invalid_reasons?project_id=${project_id}&env=${env}` + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.data.reduce((a, b) => a + b, 0) == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,

          datasets: [
            {
              data: response.data,
              backgroundColor: [ colors.red[300], colors.purple[300], colors.gray[300], colors.blue[500] ],
              hoverBackgroundColor: [ colors.red[500], colors.purple[500], colors.gray[300], colors.blue[500] ],
              borderColor: [ colors.red[500], colors.purple[500], colors.gray[300], colors.blue[500] ],
              hoverBorderColor: [ colors.red[500], colors.purple[500], colors.gray[300], colors.blue[500] ],
              fill: true,
              datalabels: {
                color: [colors.black.primary, colors.white.primary, colors.black.primary]
              }
            }
          ]
        };

        var total_sum = response.data.reduce((a, b) => a + b, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        var options = Object.assign({}, pie, {title: {display: true, text: ('Всего ' + total_sum), position: 'bottom'}});
        options['legend']['display'] = false

        options['legendCallback'] =   function(chart) {
          let currentDataset = chart.data.datasets[0];
          let dataSum = currentDataset.data.reduce((a, b) => a + b, 0);

          for (let i = 0; i < currentDataset.data.length; i++) {
            let iconColor = currentDataset.backgroundColor[i];

            let value = currentDataset.data[i];
            let valueString = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            let labelText = response.labels[i];
            let percentage = (value * 100 / dataSum).toFixed(2);

            let container = $('.card-body .js-invalid-receipts-cases').eq(i)
            container.removeClass('hidden')
            container.find('.circle-media-body .count').text(valueString);
            container.find('.circle-media-body .percentage').text('(' + percentage + '%)');
            container.find('span.text').text(labelText);
            container.find('.circle-media-icon').css('color', iconColor);
          }
        }

        let incorrectReceiptsChart2 = new Chart(ctx, {
          type: "doughnut",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: options
        });
        incorrectReceiptsChart2.generateLegend();
      }
    }
  }

  static onInit() {
    IncorrectReceipts2.Chart();
  }
}

export default IncorrectReceipts2;
