import $ from 'jquery';

import cdpInsightProfilesWithPrizes from './insights/profiles_with_prizes';
import cdpInsightProfilesWithMoreThanFivePrizes from './insights/profiles_with_more_than_five_prizes';


class customHeaderLinksContextMenu {
    constructor() {
        if ($('#custom-context-menu-header-nav').length) {
            this.init()
        }
    }

    init() {
        $('#custom-context-menu-header-nav').children('a').contextmenu((e) => {
            e.preventDefault()

            if (window.matchMedia('(min-width: 992px)').matches) {
                e.pageY -= 64
                if (!$('.az-sidebar-hide').length) {
                    e.pageX -= 240
                }
            }

            $('#open-in-new-tab-context-menu').removeClass('d-none').css({
                top: e.pageY + "px",
                left: e.pageX + "px"
            }).children('a').attr('href', e.currentTarget.href).children('span').text('Открыть в новой вкладке ' + '"' + e.currentTarget.innerText + '"')
        })

        $('html').click(() => {
            $('#open-in-new-tab-context-menu').addClass('d-none')
        })
    }
}

export default customHeaderLinksContextMenu;
