import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, value } from '../options';

class MoneyCodes {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__money-codes')
    let check = $('canvas').is('#charts__money-codes')
    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env')
    let datasets = []
    let options = singleAxes

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/codes_in_money?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.tickets_money.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        $('#charts__money-codes').parents('.card').find('.money-sum').text('Баланс за выбранный период: ' + response.sum + ' ₽')

        let total_dataset = {
          label: "Количество кодов в ₽",
          data: response.tickets_money,
          backgroundColor: colors.orange[300],
          hoverBackgroundColor: colors.orange[500],
          borderColor: colors.orange[500],
          hoverBorderColor: colors.orange[500],
          fill: false
        }

        datasets.push(total_dataset)

        let data = {
          labels: response.labels,
          datasets: datasets
        };

        let newChart = new Chart(ctx, {
          type: 'line',
          labels: response.labels,
          data: {
            datasets: data.datasets
          },
          options: {
            responsive: true,

            legend: {
              display: true,

              labels: {
                fontColor: colors.black.primary,
                fontFamily: "Roboto"
              }
            },

            tooltips: {
              mode: "index",
              intersect: true
            },

            scales: {
              xAxes: [{
                type: 'time',
                time: {
                  unit: 'day',
                  unitStepSize: 1,
                  displayFormats: {
                     'day': 'MMM DD'
                  },
                  tooltipFormat:'DD.MM.YYYY'
                }
              }]
            },

            plugins: {
              datalabels: {
                color: colors.transparent
              }
            }
          }
        });
      }
    }
  }

  static onInit() {
    MoneyCodes.Chart();
  }
}

export default MoneyCodes;
