import $ from 'jquery';
import { colors } from '../colors';
import { horizontalLabels } from '../options';

class ReceiptsContragents {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__contragents-receipts');
    let check = $('canvas').is('#charts__contragents-receipts');
    let project_id = $('[data-project-id]').data('project-id');
    let env = $('[data-env]').data('env');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    let datasets = [];

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/contragents_receipts?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.actual.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let actual_dataset = {
          label: 'Факт кол-во чеков',
          data: response.actual,
          backgroundColor: colors.yellow[300],
          hoverBackgroundColor: colors.yellow[500],
          borderColor: colors.yellow[500],
          hoverBorderColor: colors.yellow[500]
        }

        datasets.push(actual_dataset)

        if (response.plan){
          let plan_dataset = {
            label: 'План кол-во чеков',
            data: response.plan,
            backgroundColor: colors.green[300],
            hoverBackgroundColor: colors.green[500],
            borderColor: colors.green[500],
            hoverBorderColor: colors.green[500]
          }

          datasets.push(plan_dataset)
        }

        new Chart(ctx, {
          type: "horizontalBar",
          data: {
            labels: response.labels,
            datasets: datasets
          },
          options: horizontalLabels
        });
      }
    }
  }

  static onInit() {
    ReceiptsContragents.Chart();
  }
}

export default ReceiptsContragents;
