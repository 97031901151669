import $ from 'jquery';

class ImageZoomer {
  constructor() {
    if ($('.image_zoomer').length > 0) {
      this.init()
    }
  }

  init() {
    wheelzoom(document.querySelectorAll('.image_zoomer:not(.bound)'));
    $('.image_zoomer:not(.bound)').addClass('bound')
  }
}

export default ImageZoomer;
