import $ from 'jquery';
import { colors } from '../colors';
import { horizontalLabels } from '../options';

class AudienceContragents {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__contragents-members');
    let check = $('canvas').is('#charts__contragents-members');
    let project_id = $('[data-project-id]').data('project-id');
    let env = $('[data-env]').data('env');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/contragents_members?project_id=' + project_id + '&env=' + env + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.male.length == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        new Chart(ctx, {
          type: "horizontalBar",
          data: {
            labels: response.labels,
            datasets: [{
              label: 'Мужчины',
              data: response.male,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500]
            },
            {
              label: 'Женщины',
              data: response.female,
              backgroundColor: colors.red[300],
              hoverBackgroundColor: colors.red[500],
              borderColor: colors.red[500],
              hoverBorderColor: colors.red[500]
            }]
          },
          options: horizontalLabels
        });
      }
    }
  }

  static onInit() {
    AudienceContragents.Chart();
  }
}

export default AudienceContragents;
