import $ from 'jquery';


class GeoReceiptsRetailPlaces {
  constructor() {
  }

  static Chart() {
    let ctx = $('#map_receipts_retail_places');
    if (ctx.length == 0) return;

    let project_id = $('[data-project-id]').data('project-id')
    let env = $('[data-env]').data('env');
    let first_week = parseUrl('start_week');
    let end_week = parseUrl('end_week');

    $.ajax({
      url: `/api/v1/statistics/geo_receipts_retail_places?project_id=${project_id}&start_week=${first_week}&end_week=${end_week}&env=${env}`,
      method: 'GET',
      dataType: 'json',
      success: function (response) {
        initChart(response)
      }
    });

    let initChart = function(response){
      if (Object.keys(response.top_cities).length == 0){
        $(ctx).parent().html('<div class="not_enough_data">Для отображения недостаточно данных</div>')
        return;
      }

      $('#map_receipts_retail_places').vectorMap({
        map: 'ru_mill',
        markerStyle: {
          initial: {
            fill: '#304FFE',
            stroke: '#bbdefb'
          }
        },
        markers: response.markers,
        series: {
          markers: [{
            attribute: 'fill',
            scale: ['#ff80ab', '#b71c1c'],
            values: response.marker_values,
            legend: { horizontal: true }
          },{
            attribute: 'r',
            scale: [5, 10],
            values: response.marker_values,
          }]
        },
        backgroundColor: '#006994',
        onMarkerTipShow: function(event, label, index){
          label.html(
            '<b>'+response.top_cities[index].name + '</b> - '+response.top_cities[index].value
          );
        }
      })

      let table = $('#top_receipts_retail_places_table')
      table.html('')
      $.each(response.top_cities, function(){
        let tr = $('<tr></tr>')
        let name = $('<td></td>')
        name.text(this.name)
        let value = $('<td></td>')
        value.text(this.value)
        tr.append(name)
        tr.append(value)
        table.append(tr)
      })
    }
  }

  static onInit() {
    GeoReceiptsRetailPlaces.Chart();
  }
}

export default GeoReceiptsRetailPlaces;
