import Charts from './charts/charts';
import PrizeDistribution from './prize_distribution';
import Quests from './quests';
import ImageZoomer from './image_zoomer';
import sidebarNav from "./sidebar_nav";
import allProfilesStatisticsSampleData from "./all_profiles_statistics";
import segmentModals from "./segment_modals";
import segmentStatisticsSampleData from "./segment_statistics";
import insightsDiagrams from "./insights_diagrams";
import cdpStatistics from './cdp/cdp_statistics';
import cdpInsights from './cdp/cdp_insights';
import breadcrumbOverflow from "./breadcrumb_overflow";
import tableAdaptive from "./table_adaptive";
import ticketsChat from "./ticketsChat";
import userNewAccess from "./user_new_access";
import projectReceipts from "./project_receipts";
import communicationPreview from "./communication_preview";
import externalTemplatesModals from "./external_templates_modals";
import customHeaderLinksContextMenu from "./cdp/custom_context_menu";
import emailCampaignsSettings from "./email_campaigns_settings";
import solutions from "./solutions";
import emailTemplatesAutocomplete from "./emailTemplatesAutocomplete";
import TopTrafficInfo from "./cdp_profile/top_traffic_info";
import WeekTraffic from "./cdp_profile/week_traffic";
import SupportChat from "./support_chat";

class Components {
  constructor() {
    Charts.onInit();
    this.prize_distribution = new PrizeDistribution()
    this.quests = new Quests()
    this.image_zoomer = new ImageZoomer()
    this.sidebarNav = new sidebarNav()
    this.allProfilesStatisticsSampleData = new allProfilesStatisticsSampleData()
    this.segmentModals = new segmentModals()
    this.segmentStatisticsSampleData = new segmentStatisticsSampleData()
    this.insightsDiagrams = new insightsDiagrams()
    this.cdpStatistics = new cdpStatistics()
    this.cdpInsights = new cdpInsights()
    this.breadcrumbOverflow = new breadcrumbOverflow()
    this.tableAdaptive = new tableAdaptive()
    this.chat = new ticketsChat()
    this.userNewAccess = new userNewAccess()
    this.projectReceipts = new projectReceipts()
    this.externalTemplatesModals = new externalTemplatesModals()
    this.communicationPreview = new communicationPreview()
    this.customHeaderLinksContextMenu = new customHeaderLinksContextMenu()
    this.emailCampaignsSettings = new emailCampaignsSettings()
    this.solutions = new solutions()
    this.emailTemplatesAutocomplete = new emailTemplatesAutocomplete()
    this.TopTrafficInfo = new TopTrafficInfo()
    this.WeekTraffic = new WeekTraffic()
    this.support_chat = new SupportChat()
  }

}

export default Components;
