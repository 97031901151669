import $ from 'jquery';

class cdpStatisticsDeviseKind {
  constructor() {
    if ($(this.elementId()).length > 0){
      this.loading()
      this.init()
    }
  }

  init() {
    var cdpStat = this;

    $(document).ready(function() {
      setTimeout(function() {
        var result = { query: JSON.stringify(SegmentQueryBuilder.getMongoQuery()) };

        $.ajax({
          url: $(cdpStat.elementId()).data('url'),
          method: 'GET',
          data: result,
          success: function (response) {
            cdpStat.renderStats(response)
          }
        });
      }, 1000);
    })
  }

  renderStats(response){
    $(this.elementId() + ' .card-body h5.desktop_value').text(this.transform(response.desktop));
    $(this.elementId() + ' .card-body h5.mobile_value').text(this.transform(response.mobile));
    $(this.elementId() + ' .peity-donut').text(`${this.transform(response.mobile_percetage)}/100`);

    $(this.elementId() + ' .peity-donut').peity('donut', {
      after: function() {

        var line = this.svgElement('text', {
          fill: '#5b47fb',
          'font-size': '25',
          'text-anchor': 'middle',
          x: 66,
          y: 75,
        }).text(parseFloat(response.mobile_percetage).toLocaleString("ru") + '%');

        this.$svg.append(line);
      }
    });

    this.loaded();
  }

  loading(){
    $(this.elementId()).addClass('loading');
  }

  loaded(){
    $(this.elementId()).removeClass('loading');
  }

  elementId() {
    return "#cdp-stat-devise-kind"
  }

  transform(number) {
    return parseFloat(number).toLocaleString("ru")
  }
}

export default cdpStatisticsDeviseKind;
