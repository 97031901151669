import $ from 'jquery';
import { colors } from '../colors';
import { singleAxes, value } from '../options';

class RegistartionChannelsDynamic {
  constructor() {
  }

  static Chart() {
    let ctx = $('#charts__registartion_channels_dynamic');
    let check = $('canvas').is('#charts__registartion_channels_dynamic');
    let project_id = $('[data-project-id]').data('project-id');

    let first_week = parseUrl('start_week')
    let end_week = parseUrl('end_week')
    let days_from = parseUrl('days_from')
    let days_to = parseUrl('days_to')
    let period_filter_type = parseUrl('period_filter_type')
    let periodParams = '&period_filter_type=' + period_filter_type +
                       '&start_week=' + first_week + '&end_week=' + end_week +
                       '&days_from=' + days_from + '&days_to=' + days_to

    if(check) {
      $.ajax({
        url: '/api/v1/statistics/registartion_channels_dynamic?project_id=' + project_id + periodParams,
        method: 'GET',
        dataType: 'json',
        success: function (response) {
          initChart(response)
        }
      });

      let initChart = function(response){
        if (response.sms.reduce((a, b) => a + b, 0) == 0 && response.remain.website((a, b) => a + b, 0) == 0){
          $(ctx).parent().html('<div class="not_enough_data"><b>Для отображения недостаточно данных</b></div>')
          return;
        }

        let data = {
          labels: response.labels,

          datasets: [
            {
              type: "bar",
              label: "Смс",
              stack: "Stack 0",
              data: response.sms,
              backgroundColor: colors.blue[300],
              hoverBackgroundColor: colors.blue[500],
              borderColor: colors.blue[500],
              hoverBorderColor: colors.blue[500],
              datalabels: value
            },
            {
              type: "bar",
              label: "Email",
              stack: "Stack 0",
              data: response.website,
              backgroundColor: colors.orange[300],
              hoverBackgroundColor: colors.orange[500],
              borderColor: colors.orange[500],
              hoverBorderColor: colors.orange[500],
              datalabels: value
            }
          ]
        };

        let registartion_channels_dynamic = new Chart(ctx, {
          type: "bar",
          data: {
            labels: data.labels,
            datasets: data.datasets
          },
          options: singleAxes
        });

        let showValue = true
        let newValueOptions = {}

        $('#registartion-channels-dynamic-switch').on('click', function(){
          $(this).toggleClass('on')

          if(showValue == true)
          {
            showValue = false
            newValueOptions = {}
          }
          else
          {
            showValue = true
            newValueOptions = value
          }

          data.datasets.forEach(function(dataset) {
            dataset['datalabels'] = newValueOptions
          })

          registartion_channels_dynamic.update()
        })
      }
    }
  }

  static onInit() {
    RegistartionChannelsDynamic.Chart();
  }
}

export default RegistartionChannelsDynamic;
