import $ from 'jquery';


class RetailPlaceMap {
  constructor() {
  }

  static Chart() {
    let ctx = $('#retail_place_map');
    if (ctx.length == 0) return;

    let markers = $('#retail_place_map').data('markers');

    let initChart = function(markers){
      $('#retail_place_map').vectorMap({
        map: 'ru_mill',
        markerStyle: {
          initial: {
            fill: '#304FFE',
            stroke: '#bbdefb'
          }
        },
        markers: markers,
        backgroundColor: '#006994',
        onMarkerTipShow: function(event, label, index){
          label.html(
            '<b>'+ markers[index]['name'] + '</b>'
          );
        }
      })
    }

    initChart(markers);
  }

  static onInit() {
    RetailPlaceMap.Chart();
  }
}

export default RetailPlaceMap;
